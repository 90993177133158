import React, { useState,useEffect } from "react";
import styles from "./productpage.module.scss";
import SideNav from "../../Components/Admin/SideNav/SideNav";
import TopNav from "../../Components/TopNav/TopNav";
import { Container, Row, Col, Button, Table } from "reactstrap";
import { Card, CardBody, CardTitle, Badge } from "reactstrap";
import dp from "../../Assets/mari.png";
import axios from "axios";
import Constant from '../../constant.json';
import NavVenderSm from "../../Components/Admin/NavVenderSm/NavVenderSm";
import Pagination from '../../Components/paginetion';
import MyProductType from "../../Components/Admin/MyProductType/MyProductType";
import "./override.scss";
import { useAuth } from "../../context/auth";
import ProductType from "../../Components/Admin/ProductsType/ProductsType";

const AdminProductTypePage = () => {
  const auth = useAuth();
  const [isLoading, setIsLoading] = useState(false);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [modal, setModal] = useState(false);
  const toggle2 = () => setModal(!modal);
  const toggle = () => setDropdownOpen((prevState) => !prevState);

  const [state, setState] = useState({
    ProductData: {},
    pageSize : 10,
    pageOfItems: [],
    
    
  })
  const {
    ProductData,pageSize,pageOfItems
  } = state;
  useEffect(() => {
    GetProductTypeList();
  },[]);
  async function GetProductTypeList() {
    setIsLoading(true);
    let data = [];
     const config = {
      method: 'get',
      url: Constant.API_URL + '/api/product_type/list',
      headers: {
        'Content-Type': 'application/json',
        
    },
    }
    await axios(config)
    .then(function (response) {
      let result = response.data;

      setState({
        ...state,
        ProductData: result.data
      });
    }) 
    setIsLoading(false);
  }
  let reloadtable = () => {
    
    setState({
      ...state,
      ProductData: {}
    });
    GetProductTypeList();
  }
  function onproductpageChange(pageOfItems) {
  
    setState({
      ...state,
      pageOfItems: pageOfItems
    });
    
  }
  return (
    <div className={styles.main_div} >
      <ProductType modal={modal} toggle={toggle2} reloadtable={reloadtable}  />
      <span className="d-none d-md-block">
        <TopNav />
      </span>
      <span className="d-block d-md-none">
        <NavVenderSm />
      </span>
      <div className={isLoading ? "loader" : "loaderhide"}></div>
      <Container className={`${styles.container} pt-4  mr-0 ml-0 p-0`} style={{ background: 'white' }}>
        
        <Row>
          <Col xs={2} className="d-none d-md-block">
            <SideNav />
          </Col>
          <Col xs={12} md={12} sm={12}>
            <div>
              <Card className={`${styles.card} mt-4  `} >
                <CardTitle className={`${styles.headingOrder} ml-4 mt-2`}>
                  Products Type
                  <Button
                    onClick={(e) => setModal(true)}
                    className={styles.butt}
                  >
                    Add Product Type
                  </Button>
                  
                </CardTitle>
                <CardBody>
                  <Container className="p-0" className={styles.tablecontainer}>
                    <Row className={styles.row}>
                      <Col xs={4} md={4}>
                        {" "}
                        <h6
                          className={(styles.table_heading, styles.productHead)}
                        >
                          Name
                        </h6>
                      </Col>
                      <Col xs={4} md={4}>
                        <h6 className={(styles.table_heading, styles.qtyHead)}>
                          Status
                        </h6>
                      </Col>
                      <Col xs={4} md={4}>
                        <h6 className={(styles.table_heading, styles.acttHead)}>
                          Action
                        </h6>
                      </Col>
                    </Row>
                    {
                      
                      ProductData.length > 0 ?
                      pageOfItems.map((item) => (
                        <MyProductType image={dp} imgDetail="Mariuana" reloadtable={reloadtable}  ProductData={item} />
                      )
                      ) : "No data found"
                    }
                    
                  </Container>
                  {
                  ProductData.length > 0 ?
                  <div className="pagination-row">
                    <Pagination items={ProductData} onChangePage={onproductpageChange}  pageSize={pageSize} />
                </div>
                : ''
                }
                </CardBody>
              </Card>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default AdminProductTypePage;
