import React, { useState } from "react";
import style from "./product.module.scss";
import style_upload from "../ProductDetail/productdetail.module.scss";
import { Container, Row, Col } from "reactstrap";
import { Button, Form, FormGroup, Label, Input,FormFeedback, FormText } from "reactstrap";
import Pencil from "../../Assets/pencil.svg";
import { useAuth } from "../../context/auth";
import axios from "axios";
import Constant from '../../constant.json';
import { Modal } from "reactstrap";
import Swal from 'sweetalert2';

import { validateAll } from 'indicative/validator';
const ProductsType = (props) => {
  const userID = window.localStorage.getItem('userID');
  const auth = useAuth();
  const [activeStep, setActiveStep] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [state, setState] = useState({
    ProductType: '',
    errors: {},
    disabled : false
  })

  
  const {
    ProductType,disabled,errors
  } = state;
  const onChangeEvent = (e) => {
 
    const value = e.target.value;
    setState({
      ...state,
      [e.target.name]: value
    });
  };
  
const onSaveProduct = (e) => {
  if (disabled) {
    return;
}

setState(prevState => ({
  ...prevState,
  disabled: true,
}));
  const messages = {
    'ProductType.required': 'Please enter product type.',
    
  };
  const rules = {
    ProductType: 'required',
    
  };
  validateAll(state, rules, messages).then(() => {
    setIsLoading(true);
    e.preventDefault();
    postProductType()
    .then(function (response) {
      if(response.data.status == "1") {
        Swal.fire({
          icon: 'success',
          text: "Product Type added.!",
        });
        setState(prevState => ({
          ...prevState,
          disabled: false,
          ProductType: '',
          errors: {},
        }));
        props.toggle();
        props.reloadtable();
        
      }
    }).catch(err => {
      setState(prevState => ({
        ...prevState,
        disabled: false,
      }));  
      let formattedErrors = {};
      formattedErrors['error_info'] = "There is some error please contact admin."
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: formattedErrors['error_info'],
      });
      
    }) 
    setIsLoading(false);
  }).catch(errors => {
    const formattedErrors = {

    };
    setState(prevState => ({
      ...prevState,
      disabled: false,
    }));
    errors.forEach(error => formattedErrors[error.field] = error.message);

    setState({
      ...state,
      errors: formattedErrors
    });
  })
}
async function postProductType() {
  

  const config = {
    method: 'post',
    url: Constant.API_URL + '/api/product_type/create/for/user',
    data: {
      user_id:userID,
      name: ProductType,
    },
    headers: {
      'Content-Type': 'application/json',
      'x-access-token': auth.authTokens
    },
  }
  const result = await axios(config);
  return result;

}


  return (
    <Modal centered isOpen={props.modal} toggle={props.toggle}>
      <div className={isLoading ? "loader" : "loaderhide"}></div>
      <div className={` ${style.productMain} `}>
        
        <div className={style.main}>
          <Row>
            <Col xs="12" sm="12" md="12" className={`${style.formMain}  `}>
              <Container className={style.stepperContainer}>
              <a className="close" href="#" onClick={()=>props.toggle()}>×</a>
                <div className={style.stepperMain}>
                  <h1> ADD PRODUCT TYPE </h1>
                
                      <div className={style_upload.container}>
                        <Container>
                          <Row>
                            <Col xs="12" md="12">
                              <Form className={style_upload.form}>
                                <div className={style_upload.Input}>
                                <FormGroup row>
                                  <Label for="name" sm={4}>
                                    Product Type<span className={style.star}>*</span>
                                  </Label>
                                  <Col sm={8}>
                                    <div>
                                      <Input
                                        invalid = {errors.ProductName}
                                        className={style.select}
                                        type="name"
                                        name="ProductType"
                                        defaultValue = {ProductType}
                                        placeholder="Product type name"
                                        onChange={(e) => onChangeEvent(e)}
                                      />
                                    </div>
                                    <FormFeedback style={{display:'block'}}>{errors.ProductType}</FormFeedback>
                                  </Col>
                                </FormGroup>

                                  <FormGroup  row>
                                  <Col
                                      sm={12}
                                      lg={6}
                                      className={style_upload.buttonContainerback}
                                      style={{ display: "flex", justifyContent: "flex-start" }}
                                    >
                                      {/* <Button
                                        onClick={(e) => setActiveStep(2)}
                                        className="btn"
                                      >
                                        Back
                                      </Button>{" "} */}
                                    </Col>
                                    <Col
                                      sm={12}
                                      lg={6}
                                      className={style_upload.buttonContainer}
                                      style={{ display: "flex", justifyContent: "flex-start" }}
                                    >
                                      <Button
                                        onClick={(e) => onSaveProduct(e)}
                                        className="btn"
                                        disabled={disabled}
                                      >
                                        {disabled ? 'Submiting...' : 'Submit'}
                                        
                                      </Button>{" "}
                                    </Col>
                                    
                                  </FormGroup>
                                </div>
                              </Form>
                            </Col>
                          </Row>
                        </Container>
                      </div>
                  
                </div>
              </Container>
            </Col>
          </Row>
        </div>
      </div>
    </Modal>
  );
};
export default ProductsType;
