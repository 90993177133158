import React, { useState } from "react";
import "./navVenderSm.scss";
import {
  Collapse,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  NavItem,
  NavLink,
} from "reactstrap";
import Dp from "../../Assets/dp.jpg";
import { useAuth } from "../../context/auth";
import {dflt, noop, ajax, l, isset,blank, ref,makeuniqueID} from '../../helpers';
const NavVenderSm = () => {
  const auth = useAuth();
  let image_path = localStorage.getItem('profile_image_url');
  const { setAuthTokens,setAuthRole } = useAuth();
  const [collapsed, setCollapsed] = useState(true);
  const toggleNavbar = () => setCollapsed(!collapsed);
  const logout = () => {
    setAuthTokens(null);
    setAuthRole(null);
    localStorage.setItem('userID','');
    localStorage.setItem('token','');
    localStorage.setItem('name','');
    localStorage.setItem('email','');
    localStorage.setItem('role','');
    localStorage.setItem('profile_image_url','');
  }
  const _tokenexpering = localStorage.getItem('_expertime');
const current_time = new Date();
if(isset(_tokenexpering)){
   
  if(Date.parse(current_time) > Date.parse(_tokenexpering)){
    
    setAuthTokens(null);
    setAuthRole(null);
    localStorage.setItem('userID','');
    localStorage.setItem('token','');
    localStorage.setItem('name','');
    localStorage.setItem('email','');
    localStorage.setItem('role','');
    localStorage.setItem('profile_image_url','');
    const sevenDaysFromNow = new Date(new Date().setHours(new Date().getHours() + 1));
    
    localStorage.setItem('_expertime',sevenDaysFromNow);
  }
}
  return (
    <div>
      <Navbar color="white" light>
        <NavbarBrand href="/" className="mr-auto">
          <div className="dp">
            <img src={image_path != '' ? image_path : Dp} className="img img-fluid" />
          </div>
        </NavbarBrand>
        {/* <NavbarToggler onClick={toggleNavbar} className="mr-2" 
        
        /> */}
        <span
          className="navbar-toggler-icon navtog mr-2"
          onClick={toggleNavbar}
        ></span>

        <Collapse isOpen={!collapsed} navbar>
          <Nav navbar>
            <NavItem>
              <NavLink href="/dashboard">Dashboard</NavLink>
              <NavLink href="/my-orders">My Orders</NavLink>
              <NavLink href="/product-page">My Products</NavLink>
              <NavLink href="/setting">Settings</NavLink>
              <NavLink onClick={(e) => logout(e)}>Logout</NavLink>
            </NavItem>
          </Nav>
        </Collapse>
      </Navbar>
    </div>
  );
};

export default NavVenderSm;
