import React, { useState, useEffect, useRef } from "react";
import PropTypes from 'prop-types';

const propTypes = {
    items: PropTypes.array.isRequired,
    onChangePage: PropTypes.func.isRequired,
    initialPage: PropTypes.number,
    pageSize: PropTypes.number
}

const defaultProps = {
    initialPage: 1,
    pageSize: 10,

}

const Pagination = (props) => {

    const [pager, setPager] = useState({});
    const prevProps = useRef();
    useEffect(() => {

        if (props.items && props.items.length) {
            setPage(props.initialPage);
        }
        if (props.items !== prevProps.items) {
            setPage(props.initialPage);
        }
        else if (props.pageSize !== prevProps.pageSize) {

            setPage(props.initialPage);
        }

        else if (props.searchstring !== 'undefined' && props.searchstring !== prevProps.searchstring) {

            setPage(props.initialPage);
        }
        else if (props.statusfilter !== 'undefined' && props.statusfilter !== prevProps.statusfilter) {

            setPage(props.initialPage);

        }
    }, [props.items]);
    function setPage(page) {

        var { items, pageSize } = props;
        let list = [];
        let templist = [];
        let statuslist = [];
        if (props.searchstring) {
            if (props.searchstring != '') {
                let i;
                let searchcolumnArray = props.searchcolumn.searchcolumn;
                for (i = 0; i < items.length; i++) {
                    const regex = /(<([^>]+)>)/ig;
                    const description = items[i].description.replace(regex, '');
                    searchcolumnArray.map((search) =>
                            search == 'name' ? 
                                decodeURIComponent(encodeURI(items[i].name)).toLowerCase().indexOf(props.searchstring.toLowerCase()) > -1 ? 
                                    list.push(items[i])

                                : decodeURIComponent(encodeURI(items[i].description)).toLowerCase().indexOf(props.searchstring.toLowerCase()) > -1 ?
                                    list.push(items[i])
                                : null

                            : search == 'display_name' ?
                                decodeURIComponent(items[i].display_name).toLowerCase().indexOf(props.searchstring) > -1 ?
                                    list.push(items[i])
                                : null
                            : 
                             search == 'subject_name' ? 
                                decodeURIComponent(items[i].subject_name).toLowerCase().indexOf(props.searchstring) > -1 ?
                                    list.push(items[i])
                                : null
                            : search == 'tag_name' ?
                                decodeURIComponent(items[i].tag_name).toLowerCase().indexOf(props.searchstring) > -1 ?
                                    list.push(items[i])
                                :
                                null
                            : null
                        )
              
                    // if (props.searchcolumn == 'name') {
                    //     const regex = /(<([^>]+)>)/ig;
                    //     const description = items[i].description.replace(regex, '');
                    //     if (decodeURIComponent(encodeURI(items[i].name)).toLowerCase().indexOf(props.searchstring.toLowerCase()) > -1) {
                    //         list.push(items[i]);
                    //     }
                    //     else if (decodeURIComponent(encodeURI(items[i].description)).toLowerCase().indexOf(props.searchstring.toLowerCase()) > -1) {
                    //         list.push(items[i]);
                    //     }
                    // }
                    // if (props.searchcolumn == 'display_name') {
                    //     if (decodeURIComponent(items[i].display_name).toLowerCase().indexOf(props.searchstring) > -1) {
                    //         list.push(items[i]);
                    //     }
                    // }
                    // if (props.searchcolumn == 'subject_name') {
                    //     if (decodeURIComponent(items[i].subject_name).toLowerCase().indexOf(props.searchstring) > -1) {
                    //         list.push(items[i]);
                    //     }
                    // }
                    // if (props.searchcolumn == 'tag_name') {
                    //     if (decodeURIComponent(items[i].tag_name).toLowerCase().indexOf(props.searchstring) > -1) {
                    //         list.push(items[i]);
                    //     }
                    // }

                }
                items = list;
            }

        }
        if (props.statusfilter) {
            if (props.statusfilter != '') {
                let i;
                for (i = 0; i < items.length; i++) {

                    if ((decodeURIComponent(items[i].is_confirm).indexOf(props.statusfilter) > -1 || props.statusfilter == '')) {
                        statuslist.push(items[i]);
                    }


                }
                items = statuslist;
            }

        }
        // console.log(items)
        let k;
        for (k = 0; k < items.length; k++) {
            var obj = items[k]
            let newarray = Object.assign(obj, { number: k + 1 });
            templist.push(newarray);

        }
        items = templist;

        var pager = pager;

        // if (page < 1 || page > pager.totalPages) {
        //     return;
        // }

        // get new pager object for specified page

        pager = getPager(items.length, page, pageSize);


        // get new page of items from items array
        var pageOfItems = items.slice(pager.startIndex, pager.endIndex + 1);

        // update state
        setPager(pager);
        //this.setState({ pager: pager });

        // call change page function in parent component
        props.onChangePage(pageOfItems);
    }
    function getPager(totalItems, currentPage, pageSize) {

        // default to first page
        currentPage = currentPage || 1;

        // default page size is 10
        pageSize = pageSize || 10;

        // calculate total pages
        var totalPages = Math.ceil(totalItems / pageSize);

        var startPage, endPage;
        if (totalPages <= 2) {
            // less than 3 total pages so show all
            startPage = 1;
            endPage = totalPages;
        } else {
            // more than 3 total pages so calculate start and end pages
            if (currentPage <= 2) {
                startPage = 1;
                endPage = 3;
            } else if (currentPage + 1 >= totalPages) {
                startPage = totalPages - 2;
                endPage = totalPages;
            } else {
                startPage = currentPage;
                endPage = currentPage + 2;
            }
        }

        // calculate start and end item indexes
        var startIndex = (currentPage - 1) * pageSize;
        var endIndex = Math.min(startIndex + pageSize - 1, totalItems - 1);

        // create an array of pages to ng-repeat in the pager control
        var pages = [...Array((endPage + 1) - startPage).keys()].map(i => startPage + i);

        // return object with all pager properties required by the view
        return {
            totalItems: totalItems,
            currentPage: currentPage,
            pageSize: pageSize,
            totalPages: totalPages,
            startPage: startPage,
            endPage: endPage,
            startIndex: startIndex,
            endIndex: endIndex,
            pages: pages
        };
    }

    return (

        <div className="pagination-outer">

            <span>Showing {pager.startIndex + 1} - {pager.endIndex + 1} of {pager.totalItems}</span>
            <ul className="pagination">
                <li className={pager.currentPage === 1 ? 'page-item disabled' : 'page-item'}>
                    <a className="page-link" onClick={() => setPage(1)}>❮❮❮</a>
                </li>
                <li className={pager.currentPage === 1 ? 'page-item disabled' : 'page-item'}>
                    <a className="page-link" onClick={() => setPage(pager.currentPage - 1)}>❮</a>
                </li>
                {

                    typeof pager.pages != 'undefined' && pager.pages.length > 0 ?
                        pager.pages.map((page, index) =>
                            <>
                                {

                                }
                                <li key={index} className={pager.currentPage === page ? 'page-item active' : 'page-item'}>
                                    <a className="page-link" onClick={() => setPage(page)}>{page}</a>
                                </li>
                            </>
                        )
                        : ''
                }
                <li className={pager.currentPage === pager.totalPages ? 'page-item disabled' : 'page-item'}>
                    <a className="page-link" onClick={() => setPage(pager.currentPage + 1)}>❯</a>
                </li>
                <li className={pager.currentPage === pager.totalPages ? 'page-item disabled' : 'page-item'}>
                    <a className="page-link" onClick={() => setPage(pager.totalPages)}>❯❯❯</a>
                </li>
            </ul>
        </div>
    );
};

Pagination.propTypes = propTypes;
Pagination.defaultProps = defaultProps;
export default Pagination;

