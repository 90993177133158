import React, { useState } from "react";
import styles from "./ageVerify.module.scss";
import Nav from "../../Components/Nav/Nav";
import SmallNav from "../../Components/navsmall/navsmall";
import { Link } from "react-router-dom";
import { Modal, Alert, Button } from "reactstrap";
import { InputGroup, InputGroupAddon, InputGroupText, Input,FormFeedback } from "reactstrap";
import Disclaimer from "./Disclaimer";

const AgeVerify = (props) => {
  const [focused, setFocus] = useState("");
  const [age, setAge] = useState();
  const [verfiy, setVerify] = useState(false);
  // const [openModal, setOpenModal] = useState(false);
  const [state, setState] = useState({
    errors: {},
  })

  
  const {
      errors
  } = state;

  function calculate_age() {
    
    const verified = localStorage.getItem("verified");
    if(verfiy){
      localStorage.setItem("verified", JSON.stringify(verfiy));
      props.setModal(false);
      props.setOpenModal(true)
    }else{
      const formattedErrors = {

      };
       formattedErrors['verified'] = "Please Age verified.!";
      setState({
        ...state,
        errors: formattedErrors
      });
    }
    // if (JSON.parse(verified)) {
    //   props.setModal(false);
    // } else {
    //   localStorage.setItem("verified", JSON.stringify(verfiy));
    //   props.setModal(false);
    // }
  }
  // function close_disclaimer_modal(){
  //   setOpenModal(false)

  // }

  return (
    <>
    <Modal centered isOpen={props.modal}>
      <React.Fragment>
        <section className={styles.register_container}>
          <div className={styles.shadow_box}>
            <div className={styles.content_container}>
              <h1 className={styles.top_heading}>AGE VERIFICATION</h1>

              <InputGroup 
               className={styles.textboxwithage}
              >
                <InputGroupAddon addonType="prepend" style={{height:'100%'}}>
                  <InputGroupText style={{backgroundColor:'transparent !important;'}}>
                    <Input
                      invalid = {errors.verified}       
                      addon
                      type="checkbox"
                      aria-label="Checkbox for following text input"
                      onClick={()=>setVerify(!verfiy)}
                    />
                    
                  </InputGroupText>
                </InputGroupAddon>
                <Input
                type="tel"
                  invalid = {errors.verified} 
                  placeholder="I agree under penalty that I am 21 years of age"
                  disabled
                />
                <FormFeedback style={{display:'block'}}>{errors.verified}</FormFeedback>
              </InputGroup>

              <div className={styles.btn_container}>
                <Button onClick={(e) => calculate_age()} className="btn">
                  CONFIRM
                </Button>
              </div>
            </div>
            <div className={styles.register_footer}>
              <p>Blazebroker.com</p>
            </div>
          </div>
        </section>
      </React.Fragment>
    </Modal>
    {/* <Disclaimer openModal={openModal} closeModal={close_disclaimer_modal}/> */}
    </>
  );
};

export default AgeVerify;
