import React, { useState, useEffect } from "react";
import {
  Collapse,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  NavItem,
  NavLink,
} from "reactstrap";
import { Button, Badge } from "reactstrap";
import styles from "./navsmall.module.scss";
import Logo from "../../Assets/logo.svg";
import cart from "../../Assets/shopping-cart.svg";
import { useAuth } from "../../context/auth";
import {dflt, noop, ajax, l, isset,blank, ref,makeuniqueID} from '../../helpers';
import { Link, useHistory } from "react-router-dom";
const Example = (props) => {
  const [collapsed, setCollapsed] = useState(true);
  const auth = useAuth();
  const history = useHistory();
  const [badge, setBadge] = useState(0);
  const cart = localStorage.getItem("cart");
  const { setAuthTokens,setAuthRole } = useAuth();
  const logout = (e) => {
    setAuthTokens(null);
    setAuthRole(null);
    localStorage.setItem('userID','');
    localStorage.setItem('token','');
    localStorage.setItem('name','');
    localStorage.setItem('email','');
    localStorage.setItem('profile_image_url','');
    history.push("/login");
  }
  const _tokenexpering = localStorage.getItem('_expertime');
const current_time = new Date();
if(isset(_tokenexpering)){
   
  if(Date.parse(current_time) > Date.parse(_tokenexpering)){
    
    setAuthTokens(null);
    setAuthRole(null);
    localStorage.setItem('userID','');
    localStorage.setItem('token','');
    localStorage.setItem('name','');
    localStorage.setItem('email','');
    localStorage.setItem('role','');
    localStorage.setItem('profile_image_url','');
    const sevenDaysFromNow = new Date(new Date().setHours(new Date().getHours() + 1));
    
    localStorage.setItem('_expertime',sevenDaysFromNow);
  }
}
  useEffect(() => {
    if(cart){
      if (JSON.parse(cart)) {
        setBadge(JSON.parse(cart).length);
      }
    }
    
  }, [cart]);
  const toggleNavbar = () => setCollapsed(!collapsed);

  return (
    <div>
      <Navbar color="white" light>
        <NavbarBrand href="/" className="mr-auto">
          <img className={styles.logo} src={Logo} alt="logo" height="50" />
        </NavbarBrand>
        {/* <NavbarToggler onClick={toggleNavbar} className="mr-2" 
        
        /> */}
        <span
          className="navbar-toggler-icon navtog mr-2"
          onClick={toggleNavbar}
        ></span>

        <Collapse isOpen={!collapsed} navbar>
          <Nav navbar>
          { typeof auth.authTokens =='undefined' ||  auth.authTokens ==null ||  auth.authTokens =='null' ||  auth.authTokens =='' ?
          (
            <>
            <NavItem>
              <NavLink tag={Link} to="/login">Login</NavLink>
            </NavItem>
            <NavItem>
              <NavLink tag={Link} to="/register">Sign Up</NavLink>
            </NavItem>
            </>
          ) : (
            <>
            {
            auth.authRole == 'ROLE_USER' ? 
            <NavItem>
              <NavLink tag={Link} to="/dashboard">Welcome ({localStorage.getItem('name')})</NavLink>
            </NavItem>
            : ''
            }
            {
            auth.authRole == 'ROLE_ADMIN' ? 
            <NavItem>
              <NavLink tag={Link} to="/admin/dashboard">Welcome ({localStorage.getItem('name')})</NavLink>
            </NavItem>
            : ''
            }
            <NavItem>
              <NavLink tag={Link} to="#" onClick={(e) => logout(e)}>Logout</NavLink>
            </NavItem>
            </>
          )
          }
            <NavItem>
              <NavLink tag={Link} to="/my-cart">
                My cart{" "}
                <span>
                  {" "}
                  <Badge color="danger" className="badge" pill>
                    {badge}
                  </Badge>
                </span>
              </NavLink>
            </NavItem>
          </Nav>
        </Collapse>
      </Navbar>
    </div>
  );
};

export default Example;
