// user
import React, { useState } from "react";
import styles from "./myproduct.module.scss";

import { Row, Col, Container, Table, CustomInput, Button } from "reactstrap";

import { Link } from "react-router-dom";
import UserProductTypeInfoDetail from "../UserProductTypeInfoDetail/UserProductTypeInfoDetail";
// import ProductTypeInfoDetail from "../ProductTypeInfoDetail/ProductTypeInfoDetail";

// import ProductTags from "../../ProductTypeInfoDetail/ProductTags";
import axios from 'axios';
import { validateAll } from 'indicative/validator';
import { useAuth } from "../../context/auth";
import Constant from '../../constant.json';
import { Modal } from "reactstrap";
import Swal from 'sweetalert2';
import "./override.scss";
import TagsInput from 'react-tagsinput';
import 'react-tagsinput/react-tagsinput.css'; // If using WebPack and style-loader.

const MyProductType = (props) => {
  const userID = window.localStorage.getItem('userID');
 
  const auth = useAuth();
  const [modal, setModal] = useState(false);
  const toggle = () => {
    setModal(!modal)
    props.reloadtable();
  };

  const [tagsState, setTagsState] = useState([]);

  const [tagsModal, setTagsModal] = useState(false);
  const toggleTags = () => {
    setTagsModal(!tagsModal)
    // props.reloadtable();
  };

  const {
    disabled
  } = tagsState;

  let productinfo = props.ProductData;
  let tagListArray = [];
  let objectTag = {};

  if (typeof productinfo == 'undefined') {
    productinfo = {};
  }


  const productTagsByID = async (type_id) => {
    const config = {
      method: 'post',
      url: Constant.API_URL + '/api/product_type/user-product-tags-list',
      headers: {
        'Content-Type': 'application/json',
        'x-access-token': auth.authTokens
      },
      data: {
        user_id : userID,
        type_id: type_id
      }
    }
    await axios(config)
      .then(function (response) {
        let result = response.data;
        if (result.status == 1) {
          if (result.data.length > 0) {
            for (var i = 0; i < result.data.length; i++) {
              tagListArray.push(result.data[i].name);
            }
            setTagsState(tagListArray);
            setTagsModal(true);

          } else {
            setTagsModal(true);
          }
        } else {
          setTagsModal(true);
        }
      }).catch(err => {

      })
  }


  const handleChange = (tagsArray) => {
    setTagsState(tagsArray);
  }
  const [isLoading, setIsLoading] = useState(false);

  const saveProductTags = (e) => {
    if (disabled) {
      return;
    }

    // setTagsState(prevState => ({
    //   ...prevState,
    //   disabled: true,
    // }));

    const messages = {
      'tagsArray.required': 'Please enter product tags.',

    };
    const rules = {
      tagsArray: 'required',
    };

    validateAll(tagsState, rules, messages).then(async () => {
      setIsLoading(true);
      e.preventDefault();
      await postProductTags(props.productTagsID)
        .then(function (response) {
          //setTagsModal(false);
          console.log(response.data.status);
          if (response.data.status == '1') {
            Swal.fire({
              icon: 'success',
              text: "Product Tags added.!",
            });
            // 
            //props.toggle();
            //props.reloadtable();
            setIsLoading(false);
            props.reloadtable();
          } else {
            Swal.fire({
              icon: 'error',
              text: response.data.msg,
            });
            // 
            //props.toggle();
            //props.reloadtable();
            setIsLoading(false);
            //props.reloadtable();
          }
        }).catch(err => {
          setTagsState(prevState => ({
            ...prevState,
            disabled: false,
          }));
          let formattedErrors = {};
          formattedErrors['error_info'] = "There is some error please contact admin."
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: formattedErrors['error_info'],
          });

        })
      setIsLoading(false);
      //props.reloadtable();

    }).catch(errors => {
      const formattedErrors = {

      };
      tagsState(prevState => ({
        ...prevState,
        disabled: false,
      }));
      errors.forEach(error => formattedErrors[error.field] = error.message);

      setTagsState({
        ...tagsState,
        errors: formattedErrors
      });
    })
    
  }

  async function postProductTags() {
    if(tagsState.length > 0) {
      const config = {
        method: 'post',
        url: Constant.API_URL + '/api/product_type/create-user-tags',
        data: {
          'userID' : userID,
          "type_id": productinfo.id,
          "name": tagsState
        },
        headers: {
          'Content-Type': 'application/json',
          'x-access-token': auth.authTokens
        },
      }
      const result = await axios(config);
      return result;
    } else {
      return {"data":{"status":0,"msg" : "Please hit enter on tags to save tags"}}
    }
    
  }



  const deleteProduct = async (product_id) => {
    let data = [];
    const config = {
      method: 'delete',
      url: Constant.API_URL + '/api/product_type/user/delete/' + product_id,
      headers: {
        'Content-Type': 'application/json',
        'x-access-token': auth.authTokens
      },
    }
    await axios(config)
      .then(function (response) {
        let result = response.data;
        if (result.status == 1) {

          Swal.fire({
            icon: 'success',
            text: result.message,
          });
          props.reloadtable();
        } else {
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: result.message,
          });

        }

      }).catch(err => {

        let formattedErrors = {};
        formattedErrors['error_info'] = "There is some error please contact admin."
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: formattedErrors['error_info'],
        });

      })
  }

  const changeUserProductstatus = async (id, status) => {

    const config = {
      method: 'put',
      url: Constant.API_URL + '/api/product_type/update/user/product/type/' + id,
      headers: {
        'Content-Type': 'application/json',
        'x-access-token': auth.authTokens
      },
      data: {
        status: status
      }
    }
    await axios(config)
      .then(function (response) {
        let result = response.data;
        if (result.status == 1) {
          props.reloadtable();
          Swal.fire({
            icon: 'success',
            text: result.message,
          });

        } else {
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: result.message,
          });

        }

      }).catch(err => {

        let formattedErrors = {};
        formattedErrors['error_info'] = "There is some error please contact admin."
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: formattedErrors['error_info'],
        });

      })

  }
  return (
    <>
      <UserProductTypeInfoDetail modal={modal} toggle={toggle} productinfo={productinfo} />
      {/* <ProductTags modal={tagsModal} toggle={toggleTags} productTagsID={productinfo.id} tagsByIdArray={tagsState} /> */}
      {/* {["123","sdsdsd"]} */}
      <Row className={`${styles.main} mt-3`}>
        <Col xs="4" md="4" sm="4">
          <div className={styles.product}>
            <h6>{productinfo.name} </h6>
          </div>
          {/* {tagsState.map(data => (  
                    <p>{data}</p>
              ))}   */}
        </Col>

        <Col xs="4" md="4" sm="4">
          {/* <h6 className={`${styles.table_heading} ${styles.salePrice}`}> */}
          <CustomInput type="switch" id={`productstatusswitch_${productinfo.id}`} name="productstatusswitch"
            checked={productinfo.status}
            onChange={(e) => changeUserProductstatus(productinfo.id, !productinfo.status)}
          />
          {/* </h6> */}
        </Col>
        <Col xs="4" md="4" sm="4" className={styles.tablePic}>
          <Link>
            <Button
              className={styles.iconButton}
              onClick={(e) => setModal(true)} >
              <i class="fa fa-pencil" aria-hidden="true"></i>
              {/* <img src={edit} /> */}
            </Button>
          </Link>
          <Button className={styles.iconButton} onClick={(e) => deleteProduct(productinfo.id)}>
            {/* <img src={del} /> */}
            <i class="fa fa-trash" aria-hidden="true"></i>
          </Button>

          <Button className={styles.iconButton} onClick={(e) => productTagsByID(productinfo.id)}>
            {/* <img src={del} /> */}
            <i class="fas fa-tags"></i>
          </Button>

          {/* <Button className={styles.iconButton}  onClick={(e) => setTagsModal(true)}>
            <i class="fas fa-tags"></i>
          </Button> */}

        </Col>


      </Row>
      
      {/* modal  */}
      <Modal centered isOpen={tagsModal} toggle={toggleTags}>
        <div className={isLoading ? "loader" : "loaderhide"}></div>
        <Container className="mt-3">
          <div className={styles.main}>
            <Row className={styles.row}>
              <Col xs="12" md="12" sm="12">
                <div className={styles.topHeading}>
                  <Row>
                    <Col xs="8" md="8" sm="8" className={styles.left}>
                      <h3>Product Tags</h3>
                    </Col>
                    <Col xs="4" xs="4" sm="4" className={styles.right}>
                      <a className="close" href="#" onClick={() => toggleTags()}>×</a>
                    </Col>
                  </Row>
                </div>
                <hr />
                
                {/* {console.log(tagsArray)} */}
                <TagsInput value={tagsState} onChange={(e) => handleChange(e)} />
                {/* {(tags.tagsArray) ? tags.tagsArray : ''} */}
              </Col>

              <Button className={styles.btn} style={{ marginTop: '10px',backgroundColor: 'transparent', borderColor: '#009600', color: '#009600', marginLeft: '10px'}} onClick={(e) => saveProductTags(e)} disabled={disabled}>
                {disabled ? 'Saveing...' : 'Save'}
              </Button>
            </Row>
            <br />
          </div>
        </Container>
      </Modal>
      {/* +++++++++++++++++++++++++++++++++++++ */}
      <hr />
    </>
  );
};
export default MyProductType;
