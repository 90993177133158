import React, { useState } from "react";
import styles from "./myproduct.module.scss";

import { Row, Col, Container, Table, CustomInput, Button } from "reactstrap";
import { Badge } from "reactstrap";
import { Link } from "react-router-dom";

import axios from 'axios';
import { useAuth } from "../../../context/auth";
import Constant from '../../../constant.json';
import Swal from 'sweetalert2';
import "./override.scss";
import { dflt, noop, ajax, l, isset, ref, ImageExist } from '../../../helpers';
import OrderDetailsInfo from "../../OrderDetailsInfo/OrderDetailsInfo";
const OrdersList = (props) => {
  const auth = useAuth();
  const [modal, setModal] = useState(false);
  const toggle = () => {

    setModal(!modal)
    props.reloadtable();

  };
  let orderinfo = props.OrderData;


  if (typeof orderinfo == 'undefined') {
    orderinfo = {};
  }
  const orderConfirm = (e) => {
    let props_new = props;
    const remaining_stock = (orderinfo.remaining_stock - orderinfo.qty);
    if (isset(orderinfo.item_id)) {
      Swal.fire({
        title: 'Are you sure to confirm?',
        type: "warning",
        showCancelButton: true,
        confirmButtonText: `Save`,
      }).then((result) => {

        if (result.isConfirmed) {

          const config = {
            method: 'post',
            url: Constant.API_URL + '/api/products/orderconfirm',
            headers: {
              'Content-Type': 'application/json',
              'x-access-token': auth.authTokens
            },
            data: {
              id: orderinfo.item_id,
              product_id: orderinfo.id,
              remaining_stock: remaining_stock,
            }
          }

          axios(config)
            .then(function (response) {
              props.reloadtable();
              let result = response.data;
              if (result.status == 1) {

                Swal.fire({
                  text: result.message,
                  icon: 'success'
                })
              } else {
                Swal.fire({
                  text: result.message,
                  type: 'error'
                })
              }
            }, [props]).catch(err => {
              Swal.fire({
                type: 'error',
                title: 'Oops...',
                text: "please contact admin there is a some error.",
              });

            })

        }
      })
    } else {
      Swal.fire({
        text: 'There is a some error..! please contact admin.',
        icon: 'error'
      })
    }

  }

  return (
    <>
      <OrderDetailsInfo modal={modal} productinfo={orderinfo} toggle={toggle} />
      <Row className={`${styles.main} mt-3`}>

        {/* <Col xs="1" md="1" sm="1">
          <h6 className={`${styles.table_heading} ${styles.salePrice}`}>
            {orderinfo.order_id}
          </h6>
        </Col> */}
        <Col xs="3" md="3" sm="3">
          <div className={styles.product}>
            <img
              src={
                ImageExist(Constant.API_URL + Constant.RESIZE_IMAGE + orderinfo.image) ?
                Constant.API_URL + Constant.RESIZE_IMAGE + orderinfo.image :
                  props.image
              }
              className={`${styles.image} img img-fluid`}
              alt="image"
            />

            <h6>{orderinfo.name} </h6>
          </div>
        </Col>
        <Col xs="2" md="2" sm="2">
          <h6 className={`${styles.table_heading} ${styles.salePrice}`}>
            {orderinfo.seller_name}
          </h6>
        </Col>
        <Col xs="1" md="1" sm="1">
          <h6 className={`${styles.table_heading} ${styles.salePrice}`}>
            {orderinfo.price}
          </h6>
        </Col>
        <Col xs="2" md="2" sm="2">
          <h6 className={`${styles.table_heading} ${styles.salePrice}`}>
            {orderinfo.user_email}
          </h6>
        </Col>
        {/* <Col xs="2" md="2" sm="2">
          <h6 className={`${styles.table_heading} ${styles.salePrice}`}>
          {orderinfo.user_mobile}
          </h6>
        </Col> */}
        <Col xs="1" md="1" sm="1">
          <h6 className={`${styles.table_heading} ${styles.salePrice}`}>
            {orderinfo.qty}
          </h6>
        </Col>
        <Col xs="3" md="3" sm="3">
          {
            orderinfo.is_confirm
              ? <Badge color="secondary" className="btn-success" style={{ cursor: 'default' }}>Order confirm</Badge>
              :
              <div>
                <Button className={styles.ButtonPadding} style={{ padding: '5px' }} onClick={(e) => orderConfirm(e)}>Confirm</Button>
                {" "}
                <Button className={styles.ButtonPadding}
                  style={{ padding: '5px' }}
                  onClick={(e) => setModal(true)}
                >Details</Button>
              </div>

          }
          {/* <h6 className={`${styles.table_heading} ${styles.salePrice}`}> */}
          {/* <CustomInput type="switch" id={`orderstatusswitch_${orderinfo.order_id}`} name="orderstatusswitch" 
            checked={orderinfo.is_confirm} 
            disabled = {orderinfo.is_confirm} 
            
            onChange={(e)=> changeuserstatus(info.id,!info.status)}
            /> */}
          {/* </h6> */}
        </Col>

      </Row>

      <hr />
    </>
  );
};
export default OrdersList;
