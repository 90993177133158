import React, { useState, useEffect } from "react";
import styles from "./home.module.scss";
import Nav from "../../Components/Nav/Nav";
import axios from "axios";
import SmallNav from "../../Components/navsmall/navsmall";
import Footer from "../../Components/Footer/Footer";
import Search from "../../Components/Search/Search";
import Cards from "../../Components/Card/Card";
import { useHistory } from "react-router-dom";
import AgeVerify from "../../Components/AgeVerify/AgeVerify";
import { Container, Row, Col } from "reactstrap";
import SearchNav from "../../Components/SearchNav/SearchNav";
import Pagination from '../../Components/paginetion';
import ProductDetails from "../ProductDetails/ProductDetails";
import Constant from '../../constant.json';
import MetaTags from 'react-meta-tags';
import Disclaimer from "../../Components/AgeVerify/Disclaimer";
import { dflt, noop, ajax, l, isset, blank, ref, makeuniqueID } from '../../helpers';

const Home = () => {
  const [distance, setDistance] = useState(0);
  const [latitude, set_latitude] = useState("");
  const [longitude, set_longitude] = useState("");

  /* Disclaimer popup */
  const [openModal, setOpenModal] = useState(false);
  /* close */

  const [modal, setModal] = useState(false);
  const [modal2, setModal2] = useState(false);
  const [id, setId] = useState(null);
  const [query, setQuery] = useState("");

  const [queryproduct, setQueryproduct] = useState("");
  const [loading, setLoading] = useState(false);
  const [isLoading, setisLoading] = useState(false);
  const [message, setMessage] = useState("");
  const [uniqueID, setuniqueID] = useState(makeuniqueID());
  const [totalResults, setTotalResults] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [currentPageNo, setCurrentPageNo] = useState(0);
  const [cartItem, setCartItem] = useState({});
  const [searchcolumn, setSearchcolumn] = useState({
    searchcolumn : ["name","tag_name"]
  });
  const [state, setState] = useState({
    product: [],
    pageSize: 18,
    pageOfItems: [],
    products: []
  })
  function close_disclaimer_modal() {
    localStorage.setItem("accept", true);
    setOpenModal(false)

  }

  const {
    product, pageOfItems, pageSize, products
  } = state;
  const [data, setData] = useState([]);
  const [pagination, setPagination] = useState(false);
  var cancel = "";

  const history = useHistory();
  const showDetails = (id) => {
    setisLoading(true);
    GetProductDetails(id).then(async () => {
      setId(id);
      setModal2(true);
      setisLoading(false);
    });

  };
  const GetProductDetails = async (productId) => {
    const config = {
      method: 'get',
      url: Constant.API_URL + '/api/products/' + productId,

    }
    await axios(config)
      .then(async (response) => {
        let result = response.data;
        if (result.status == 1) {
          setState({
            ...state,
            products: result.data
          });
          //products.push(result.data);
          return true;
        }
      });

  }
  const showPrevLink = 1 < currentPageNo;
  const showNextLink = totalPages > currentPageNo;

  localStorage.setItem("data", JSON.stringify(data));
  const delay = ms => new Promise(res => setTimeout(res, ms));
  async function GetCartList() {

    const config = {
      method: 'post',
      url: Constant.API_URL + '/api/cartitem/list',
      data: {
        session_id: uniqueID,
      },
    }
    await axios(config)
      .then(function (response) {
        let result = response.data;
        if (result) {
          let obj = result.data;
          var rows = [];
          for (var i = 0; i < obj.length; i++) {
            const item = {
              product: JSON.parse(obj[i].product),
              qty: obj[i].cart_qty,
              price: obj[i].price * obj[i].cart_qty,
              uniqueID: obj[i].uniqueID,
              CartId: obj[i].cart_id,
            };
            rows.push(item);

          }
          localStorage.setItem("cart", JSON.stringify(rows));
          //localStorage.setItem("cart", JSON.stringify(obj));
          setCartItem(obj);

        }
      })
  }
  useEffect(() => {
    const verified = localStorage.getItem("verified");
    const accept = localStorage.getItem("accept");


    if (JSON.parse(verified)) {
      setModal(false);
      if (accept) {
        setOpenModal(false);
      } else {
        setOpenModal(true);
      }
    } else {
      setModal(true);
    }
 
    (async () => {

      await GetProductList();
      await GetCartList();
      await fetchData(0, query, '');


    })();



  }, []);
  const fetchData = async (updatedPageNo, query, queryproduct) => {

    //await delay(2000);

    setisLoading(true);
    const pageNo = updatedPageNo ? `&page=${updatedPageNo}` : "";

    // const searchUrl = `https://pixabay.com/api/?key=12413278-79b713c7e196c7a3defb5330e&q=${query}${pageNo}`;
    const searchUrl = Constant.API_URL + '/api/products?name=' + query + pageNo;

    // dont want to query results with every key press
    // only query results when done typing
    if (cancel) {
      cancel.cancel();
    }

    cancel = axios.CancelToken.source();

    await axios
      .get(searchUrl, { cancelToken: cancel.token })
      .then(async (response) => {
        console.log("response");
        console.log(response);
        if (query == '') {

          await setData([]);
          setisLoading(false);
          return;
        }
        let result = response.data;
        console.log(result);
        if (result) {
          const res = result.data;
          console.log(res);
          const total = result.total;

          const totalPagesCount = getPageCount(total, 18);
          const resultNotFOundMsg = !result.total
            ? "No more results homes"
            : "";

          setData(res);
          setLoading(false);
          setMessage(resultNotFOundMsg);
          setTotalResults(total);
          setTotalPages(totalPagesCount);
          if (total > 18) {
            setPagination(true);
          } else {
            setPagination(false);
          }
        }
        setisLoading(false);
        setCurrentPageNo(updatedPageNo);
      })
      .catch((error) => {
        setisLoading(false);
        if (axios.isCancel(error) || error) {
          setLoading(false);
          setMessage("No results homes");
        }
      });
  };
  const handleSearch = async (event) => {
    setQuery(event);
    if (event == '') {
      fetchData(0, event)
    }
  };
  const handleSearchproduct = (event) => {
    setQueryproduct(event.target.value);
  };
  const getPageCount = (total, denominator) => {
    const divisible = 0 === total % denominator;
    const valueToBeAdded = divisible ? 0 : 1;
    return Math.floor(total / denominator) + valueToBeAdded;
  };

  const handlebutton = () => {
    setCurrentPageNo(0);
    fetchData(0, query, queryproduct);

  };
  const handlePageClick = (type) => {
    // event.preventDefault();
    if (type === "next") {
      let changepage = currentPageNo + 1;
      setCurrentPageNo(changepage);
      fetchData(changepage, query, queryproduct);
    } else if (type === "prev" && currentPageNo > 0) {
      let changepage = currentPageNo - 1;
      setCurrentPageNo(changepage);
      fetchData(changepage, query, queryproduct);
    }
  };
  function onproductpageChange(pageOfItems) {

    setState({
      ...state,
      pageOfItems: pageOfItems
    });

  }
  async function GetProductList() {
    let data = [];
    const config = {
      method: 'get',
      url: Constant.API_URL + '/api/products',
    }
    await axios(config)
      .then(function (response) {
        let result = response.data;
        if (result) {
          let obj = result.data;
          let count = obj.length;

          for (var i = 0; i < count; i++) {
            let data_temp = {};
            data_temp['key'] = obj[i].id;
            data_temp['value'] = obj[i].name;
            data.push(data_temp);
          }
          setState({
            ...state,
            product: data
          });
        }
      })
  }
  return (
    <div
      style={{
        minHeight: "100vh",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
      }}
    >
      <div className={isLoading ? "loader" : "loaderhide"}></div>
      <MetaTags>
        <title>Home</title>
        <meta name="description" content="Blaze Broker" />
        <meta property="og:title" content="Blaze Broker - Home" />
      </MetaTags>

      <ProductDetails id={id} modal={modal2} products={products} cartitem={cartItem} setModal={setModal2} />
      <AgeVerify modal={modal} setModal={setModal} setOpenModal={setOpenModal} />
      <Disclaimer openModal={openModal} closeModal={close_disclaimer_modal} />
      <span className="d-none d-md-block">
        <Nav />
      </span>
      <span className="d-block d-md-none">
        <SmallNav />
      </span>
      <Container className={styles.container}>
        <Search
          query={query}
          queryproduct={queryproduct}
          handleSearch={handleSearch}
          handleSearchproduct={handleSearchproduct}
          fetchData={handlebutton}
          product={product}
          fetchDatasearch={fetchData}
        />

        <Row>
          {/* {
            data.map((item, index) => (
              <>
                <p>{item.name}</p>
                <br />
              </>
            ))
          } */}
          {
            (data.length > 0) ? (
              pageOfItems.map((item, index) => (
                <Col
                  xs="2"
                  key={item.id}
                  className="col-sm-6 col-lg-2 col-md-4 col-6"
                  key={index}
                >

                  <div onClick={(e) => showDetails(item.id)}>
                    <Cards
                      name={item.name}
                      image={item.image ? Constant.API_URL + Constant.RESIZE_IMAGE + item.image : 'https://www.visitcalifornia.com/sites/visitcalifornia.com/files/styles/welcome_image/public/vc_spotlightrooftopretreats_hero_sandiego_su_theandaz_rooftoppool_1280x640.jpg'}
                      price={item.price ? item.price : 0}
                    />
                  </div>
                </Col>
              ))
            )
              :

              // (query != '') ? 
              // (

              <Col className="col-12">
                {/* <h2 style={{color:'#009600'}}>No record Found</h2> */}
              </Col>
            // )
            // : ''


          }

        </Row>
        {/* {pagination ? (
          <SearchNav
            loading={loading}
            // showPrevLink={showPrevLink}
            // showNextLink={showNextLink}
            handlePrevClick={() => handlePageClick("prev")}
            handleNextClick={() => handlePageClick("next")}
          />
        ) : null} */}
        {
          data.length > 0 ?
            <div className="pagination-row">
              {/* {console.log(searchcolumn)} */}
              {/* {alert( data.length)} */}
              <Pagination items={data} onChangePage={onproductpageChange} searchstring={query} searchcolumn={searchcolumn} pageSize={pageSize} />
            </div>
            : ''
        }
      </Container>

      <Footer />
    </div>
  );
};

export default Home;
