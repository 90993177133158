import React,{useState} from "react";
import { Route } from "react-router-dom";
import {
  Container,
  Col,
  Row,
  FormGroup,
  Label,
  Input,
  CustomInput,
  Button,
  FormFeedback, FormText, Alert
} from "reactstrap";
import Swal from 'sweetalert2';
import styles from "./infodetail.module.scss";
import FormInput from "../FormInput/FormInput";
import { validateAll } from 'indicative/validator';
import axios from 'axios';
import { useAuth } from "../../context/auth";
import Constant from '../../constant.json';
import edit from "../../Assets/edit.svg";
import dp from "../../Assets/mari.png";
import styles_text from "../FormInput/form-input.module.scss";
import { Modal } from "reactstrap";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

const UserProductTypeInfoDetail = (props) => {
  const auth = useAuth();
  const info = props.productinfo;
  
  const [isLoading, setIsLoading] = useState(false);
  const [state, setState] = useState({
    ProductType: info.name,
    errors: {},
  })

  
  const {
    ProductType,errors
  } = state;
  const onChangeEvent = (e) => {

    const value = e.target.value;
    setState({
      ...state,
      [e.target.name]: value
    });
  };

  const onSubmitSave = (e) => {
    
    e.preventDefault();
    setIsLoading(true);
    const messages = {
      'ProductType.required': 'Product type cannot be empty.',
      
    };
    const rules = {
      ProductType: 'required',
      
    };
    validateAll(state, rules, messages)
    .then(() => {
      
      postUpdateProducttype()
        .then(function (response) {
          let result = response.data;
          Swal.fire({
            icon: 'success',
            text: result.message,
          });
         props.toggle();

        }).catch(err => {
          
          let formattedErrors = {};
          formattedErrors['error_info'] = "There is some error please contact admin."
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: formattedErrors['error_info'],
          });
          
        }) 
        setIsLoading(false);
    })
    .catch(errors => {
      const formattedErrors = {

      };
      
      errors.forEach(error => formattedErrors[error.field] = error.message);

      setState({
        ...state,
        errors: formattedErrors
      });
      setIsLoading(false);
    })
  }
  async function postUpdateProducttype() {

    
    const config = {
      method: 'put',
      url: Constant.API_URL + '/api/product_type/update/user/product/type/'+info.id,
      data: {
        name: ProductType,
        
      },
      headers: {
        'Content-Type': 'application/json',
        'x-access-token': auth.authTokens
      },
      
    }
    const result = await axios(config);
    return result;

  }

  return (
    <Modal centered isOpen={props.modal} toggle={props.toggle}>
      <div className={isLoading ? "loader" : "loaderhide"}></div>
      <Container className="mt-3">
        <div className={styles.main}>
          <Row className={styles.row}>
            <Col xs="12" md="12" sm="12">
              <div className={styles.topHeading}>
                <Row>
                  <Col xs="8" md="8" sm="8" className={styles.left}>
                    <h3>Edit Product</h3>
                  </Col>
                  <Col xs="4" xs="4" sm="4" className={styles.right}>
                    {/* <img src={edit} /> */}
                    <a className="close" href="#" onClick={()=>props.toggle()}>×</a>
                  </Col>
                </Row>
              </div>
              <hr />

              <FormGroup row className={`${styles_text.main}`}>
                <Label
                  for="exampleEmail"
                  className={`${styles_text.label} ${props.labelSize ? styles_text.labelSize : ""
                    }`}
                  sm={4}
                >
                  Product Type
                  <span className={styles_text.star}>*</span>{" "}
                </Label>
                <Col sm={8}>
                  <Input
                  invalid = {errors.ProductType}
                    type='text'
                    name="ProductType"
                    id="ProductType"
                    className={`${styles_text.input}`}
                    placeholder="Enter Product type"
                    defaultValue={info.name}
                    onChange={(e) => onChangeEvent(e)}
                  />
                  <FormFeedback style={{display:'block'}}>{errors.ProductType}</FormFeedback>
                </Col>
                
              </FormGroup>
             </Col>

            <Button className={styles.btn} onClick={(e) => onSubmitSave(e)}>Save</Button>
          </Row>
          <br />
        </div>
      </Container>
    </Modal>
  );
};

export default UserProductTypeInfoDetail;
