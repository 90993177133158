import React,{useState} from "react";
import { BrowserRouter as Router,Redirect, Switch, Route } from "react-router-dom";
import Home from "../Pages/Home/Home";
import SignUp from "../Pages/SignUp/SignUp";
import SignIn from "../Pages/SignIn/SignIn";
import MyCart from "../Pages/Mycart/MyCart";
import ChangePassword from "../Pages/ChangePassword/ChangePassword";

import PaymentMethod from "../Components/PaymentMethod/PaymentMethod";

import ProductDetails from "../Pages/ProductDetails/ProductDetails";
import Thanks from "../Pages/Thanks/Thanks";
import TermsAndCondition from "../Pages/TermsAndCondition/TermsAndCondition";
import SuppliersPolicy from "../Pages/SuppliersPolicy/SuppliersPolicy";
import FAQ from "../Pages/FAQ/FAQ";
import NotFoundPage from "../Pages/NotFoundPage/NotFoundPage";
import OrderConfirm from "../Pages/OrderConfirm/OrderConfirm";

import SendEmail from "../Pages/SendEmail/SendEmail";
import ResetPassword from "../Pages/ResetPassword/ResetPassword";
import VerifyCode from "../Pages/VerifyCode/VerifyCode";

import { AuthContext } from "../context/auth";
import ProtectedRoute from '../ProtectedRoute';

import PrivateRoute from '../PrivateRoute'; 
import {dflt, noop, ajax, l, isset, ref} from '../helpers';
import IdleTimer from 'react-idle-timer';
import { useAuth } from "../context/auth";

const AppRoutes = (props) => {
  
  
  const isAuthenticated = useAuth();
  let existingTokens = null;
  if(typeof localStorage.getItem("tokens")!== 'undefined' && localStorage.getItem("tokens")!=undefined && localStorage.getItem("tokens")!='undefined' ) {
    existingTokens = JSON.parse(localStorage.getItem("tokens"));
  }
  const [authTokens, setAuthTokens] = useState(existingTokens);   
  
  const setTokens = (data) => {
    localStorage.setItem("tokens", JSON.stringify(data));
    setAuthTokens(data);
  }
  let existingrole = null;
  if(isset(localStorage.getItem("role"))) {
    existingrole = localStorage.getItem("role");
  }
  const [authRole, setAuthRole] = useState(existingrole);   
  
  const setrole = (data) => {
    localStorage.setItem("role", data);
    setAuthRole(data);
  }
  
  const [state, setState] = useState({
    
    timeout:((1000 * 60)/2)*60,
    showModal: false,
    isTimedOut: false,
    

  
})
const {
  timeout,showModal,isTimedOut
} = state; 
let idleTimer = null;

const _onAction= (e) => {
  const sevenDaysFromNow = new Date(new Date().setHours(new Date().getHours() + 1));

  localStorage.setItem('_expertime',sevenDaysFromNow);
  setState({
    ...state,
    isTimedOut: false
  });
}
const _onActive = (e) => {
  const sevenDaysFromNow = new Date(new Date().setHours(new Date().getHours() + 1));

  localStorage.setItem('_expertime',sevenDaysFromNow);
    setState({
      ...state,
      isTimedOut: false
    });
}

const _onIdle = (e) => {
  if (isTimedOut) {
    setAuthTokens(null);
    setAuthRole(null);
    localStorage.setItem('userID','');
    localStorage.setItem('token','');
    localStorage.setItem('name','');
    localStorage.setItem('email','');
    localStorage.setItem('role','');
  } else {
    idleTimer.reset();
    setState({
      ...state,
      isTimedOut: true
    });
    
  }
  
}


  
  return (
  
    <AuthContext.Provider value={{ authTokens, setAuthTokens: setTokens,authRole, setAuthRole: setrole }}>
      <IdleTimer
         ref={ref => { idleTimer = ref }}
         element={document}
         onActive={(e) => _onActive(e)}
         onIdle={(e) => _onIdle(e)}
         onAction={(e) => _onAction(e)}
         debounce={250}
         timeout={timeout} />
      <Router>
        <Switch>
          <Route exact path="/" component={Home}></Route>
          <Route path="/change-password" component={ChangePassword}></Route>
          <Route path="/register" component={SignUp}></Route>
          <Route path="/login" component={SignIn}></Route>
          <Route path="/send-email" component={SendEmail}></Route>
          <Route path="/reset-password" component={ResetPassword}></Route>
          <Route path="/verify-code" component={VerifyCode}></Route>
          <Route path="/my-cart" component={MyCart}></Route>
          <Route path="/payment-method" component={PaymentMethod}></Route>
          <Route path="/thanks" component={Thanks}></Route>
          <Route path="/terms-and-condition" component={TermsAndCondition}></Route>
          <Route path="/suppliers-policy" component={SuppliersPolicy}></Route>
          <Route path="/faq" component={FAQ}></Route>
          <Route path="/order-confirm/:productId" component={OrderConfirm}></Route>
          
          <Route
          path="/product-detail/:productId"
          component={ProductDetails}
          ></Route>
       
          {
              
            ProtectedRoute.map((route, idx) => {
              
              return route.component ? (
                 <PrivateRoute
                  path={route.path}
                  type = {route.type}
                  component={route.component}
                />
                      
                      
                  
                 
              ) : (null);
            })
            
          }
          <Route path="/404" component={NotFoundPage}></Route>
          <Redirect to="/404" />

        </Switch>
        
      </Router>  
    
    </AuthContext.Provider>
   
  );
};

export default AppRoutes;
