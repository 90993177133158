import React,  { useState,useEffect } from "react";

import { useAuth } from "../../context/auth";
import Nav from "../../Components/Nav/Nav";
import SmallNav from "../../Components/navsmall/navsmall";
import { Link, useHistory  } from "react-router-dom";
import { Container, Row, Col } from "reactstrap";
import Footer from "../../Components/Footer/Footer";
import axios from "axios";

const TermsAndCondition = () => {
  const auth = useAuth();
  let history = useHistory();

  const [extHTML, setExtHTML] = useState('');

  function fetchExternalHTML() {
    axios.get('/terms.html').then(function(response) {
        console.log("response")
        console.log(response)
        setExtHTML(response.data);
    });
}

useEffect(() => {
  fetchExternalHTML()
  return () => {}
}, [])
  
  return (
    <div
      style={{
        minHeight: "100vh",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
      }}
    >
      <span className="d-none d-md-block">
        <Nav />
      </span>
      <span className="d-block d-md-none">
        <SmallNav />
      </span>
      <Container style={{height:'100vh'}}>
      <div class="align-middle">
  <div class="row justify-content-center align-self-center">
      <div class="text-center" style={{paddingBottom:'10rem'}}>
            <article id="post-108" class="post-108 page type-page status-publish hentry">
                  <header class="entry-header">
                      <h1 class="entry-title">Terms and Condition</h1>
                  </header>
                  { 
                    <div dangerouslySetInnerHTML={{ __html: extHTML }} />
                  }
            </article>
        </div>
        </div>
        </div>
      </Container>
      <Footer />
  </div>
  );
};

export default TermsAndCondition;
