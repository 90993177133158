import React, { useState, useEffect } from "react";
import styles from "./SideNav.module.scss";
import "./override.scss";
import { Link } from "react-router-dom";
import { ListGroup, ListGroupItem } from "reactstrap";
import dashboard from "../../Assets/dashboard.svg";
import dashboardActive from "../../Assets/dashboard-active.svg";
import help from "../../Assets/help.svg";
import helpActive from "../../Assets/help-active.svg";
import invoice from "../../Assets/invoice.svg";
import invoiceActive from "../../Assets/invoice-active.svg";
import myOrder from "../../Assets/my-order.svg";
import myOrderActive from "../../Assets/my-order-active.svg";
import product from "../../Assets/product.svg";
import productActive from "../../Assets/product-active.svg";
import promotion from "../../Assets/promotion.svg";
import promotionActive from "../../Assets/promotion-active.svg";
import setting from "../../Assets/setting.svg";
import settingActive from "../../Assets/setting-active.svg";
import sideLine from "../../Assets/side-line.svg";
import sideLineWhite from "../../Assets/side-line-white.svg";
import { Container, Row, Col, Button } from "reactstrap";
import Logo from "../../Assets/logo.svg";

const SideNav = (props) => {
  const openNav = () => {
    document.getElementById("mySidenav").style.width = "22%";
    document.getElementById("Overly").style.display = "block";
  };
  // props.setOpenCart(openNav);
  const closeNav = () => {
    document.getElementById("mySidenav").style.width = "0";
    document.getElementById("Overly").style.display = "none";
  };

  const [selected, set_selected] = useState("dashboard");
  useEffect(() => {
    if (window.location.pathname === "/dashboard") {
      set_selected("dashboard");
    } else if (window.location.pathname === "/my-orders") {
      set_selected("my-order");
    } else if (window.location.pathname === "/product-page") {
      set_selected("product");
    }
    //  else if (window.location.pathname === "/product-tags") {
    //   set_selected("product-tags");
    // } 
    else if (window.location.pathname === "/promotion") {
      set_selected("promotion");
    }else if (window.location.pathname === "/product-type") {
      set_selected("product-type");
    } else if  (window.location.pathname === "/invoice") {
      set_selected("invoice");
    } else if (window.location.pathname === "/help-center") {
      set_selected("help-center");
    } else if (window.location.pathname === "/setting") {
      set_selected("settings");
    } else {
      set_selected("");
    }
  }, []);
  return (
    <Container>
      <Row>
        <Col xs="4">
          <div id="mySidenav" className={styles.sidenav} > 
          {/* style={{ borderRight : '1px solid #e2e2e2' }} */}
              <Link to="/"><img src={Logo} className={`${styles.logo} img img-fluid`} style={{margin:'0.5rem'}} /></Link>
            
            <ListGroup style={{paddingLeft:'10px'}}>
              <Link className={`${styles.items}`} to="/dashboard">
                <ListGroupItem
                  className={`${
                    selected === "dashboard" ? styles.changeColor : ""
                  }`}
                >
                  {selected === "dashboard" ? (
                    <img src={sideLineWhite} style={{paddingLeft:'10px'}} className="img img-fluid" />
                  ) : (
                    <img src={sideLineWhite} className="img img-fluid" />
                  )}
                  {selected === "dashboard" ? (
                    <img
                      
                      className="img img-fluid mr-2 ml-4"
                    />
                  ) : (
                    <img  className="img img-fluid mr-2 ml-4" />
                  )}
                  Dashboard
                </ListGroupItem>
              </Link>
              <Link className={`${styles.items}`} to="/my-orders">
                <ListGroupItem
                  className={`${
                    selected === "my-order" ? styles.changeColor : ""
                  }`}
                >
                  {selected === "my-order" ? (
                    <img src={sideLineWhite} style={{paddingLeft:'10px'}} className="img img-fluid" />
                  ) : (
                    <img src={sideLineWhite} className="img img-fluid" />
                  )}
                  {selected === "my-order" ? (
                    <img
                      
                      className="img img-fluid mr-2  ml-4"
                    />
                  ) : (
                    <img  className="img img-fluid mr-2  ml-4" />
                  )}
                  My Orders
                </ListGroupItem>
              </Link>
              <Link className={`${styles.items}`} to="/product-page">
                <ListGroupItem
                  className={`${
                    selected === "product" ? styles.changeColor : ""
                  }`}
                >
                  {selected === "product" ? (
                    <img src={sideLineWhite} style={{paddingLeft:'10px'}} className="img img-fluid" />
                  ) : (
                    <img src={sideLineWhite} className="img img-fluid" />
                  )}
                  {selected === "product" ? (
                    <img
                      
                      className="img img-fluid mr-2  ml-4"
                    />
                  ) : (
                    <img  className="img img-fluid mr-2  ml-4" />
                  )}
                  Products
                </ListGroupItem>
              </Link>
              <Link className={`${styles.items}`} to="/product-type">
                <ListGroupItem
                  className={`${
                    selected === "product-type" ? styles.changeColor : ""
                  }`}
                >
                  {selected === "product-type" ? (
                    <img src={sideLineWhite} style={{paddingLeft:'10px'}} className="img img-fluid" />
                  ) : (
                    <img src={sideLineWhite} className="img img-fluid" />
                  )}
                  {selected === "product-type" ? (
                    <img
                      
                      className="img img-fluid mr-2  ml-4"
                    />
                  ) : (
                    <img  className="img img-fluid mr-2  ml-4" />
                  )}
                  Products Type
                </ListGroupItem>
              </Link>
              {/* <Link className={`${styles.items}`} to="/product-tags">
                <ListGroupItem
                  className={`${
                    selected === "product-tags" ? styles.changeColor : ""
                  }`}
                >
                  {selected === "product-tags" ? (
                    <img src={sideLineWhite} style={{paddingLeft:'10px'}} className="img img-fluid" />
                  ) : (
                    <img src={sideLineWhite} className="img img-fluid" />
                  )}
                  {selected === "product-tags" ? (
                    <img
                      
                      className="img img-fluid mr-2  ml-4"
                    />
                  ) : (
                    <img  className="img img-fluid mr-2  ml-4" />
                  )}
                  Product Tags
                </ListGroupItem>
              </Link> */}

              <Link className={`${styles.items}`} to="/setting">
                <ListGroupItem
                  className={`${
                    selected === "settings" ? styles.changeColor : ""
                  }`}
                >
                  {selected === "settings" ? (
                    <img src={sideLineWhite} style={{paddingLeft:'10px'}} className="img img-fluid" />
                  ) : (
                    <img src={sideLineWhite} className="img img-fluid" />
                  )}
                  {selected === "settings" ? (
                    <img
                      
                      className="img img-fluid mr-2  ml-4"
                    />
                  ) : (
                    <img  className="img img-fluid mr-2  ml-4" />
                  )}
                  Settings
                </ListGroupItem>
              </Link>
            </ListGroup>
          </div>
        </Col>
        <Col xs="8">
          <div className={styles.overly} id="Overly" onClick={closeNav}></div>
        </Col>
      </Row>
    </Container>
  );
};

export default SideNav;
