import React ,{useState,useEffect}from 'react'
import styles from "./ageVerify.module.scss";
import { Modal, Alert, Button ,Col,Row ,Container  } from "reactstrap";
import { InputGroup, InputGroupAddon, InputGroupText, Input, FormFeedback,ModalBody } from "reactstrap";
import axios from "axios";


const Disclaimer = (props) => {
    const [openTermsModalState, setopenTermsModalState] = useState(false)
    const [extHTML, setExtHTML] = useState('');
    
    function terms(){
        setopenTermsModalState(true)
    }
    function closeTermsModalState(){
        setopenTermsModalState(false)
    }
    function fetchExternalHTML() {
        axios.get('/terms.html').then(function(response) {
            console.log("response")
            console.log(response)
            setExtHTML(response.data);
        });
    }
    useEffect(() => {
        fetchExternalHTML()
        return () => {}
    }, [])
    return (
        <>
        <Modal centered isOpen={props.openModal} >
            <React.Fragment>
                <section className={styles.register_container}>
                    <div className={styles.shadow_box}>
                        <div className={styles.content_container}>
                            <h1 className={styles.top_heading}>Disclaimer</h1>
                            <ModalBody>
                                <p>I agree to <span style={{color:'#fa7814',cursor:'pointer'}} onClick={(e)=>terms(e)} >terms</span> of service.</p>
                                </ModalBody>


                            <div className={styles.btn_container}>
                                <Button onClick={(e) =>props.closeModal(e)} className="btn">
                                    CONFIRM
                                </Button>
                            </div>
                        </div>
                        <div className={styles.register_footer}>
                            <p>Blazebroker.com</p>
                        </div>
                    </div>
                </section>
            </React.Fragment>
        </Modal>

            <Modal centered isOpen={openTermsModalState} size="xl" >
               <Container className="mt-3">
                    <div className={styles.main}>
                        <Row className={styles.row}>
                        <Col xs="12" md="12" sm="12">
                            <div className={styles.topHeading}>
                            <Row>
                                <Col xs="8" md="8" sm="8" className={styles.left}>
                                <h3>Terms of Service</h3>
                                </Col>
                                <Col xs="4" xs="4" sm="4" className={styles.right}>
                                <a className="close" href="#" onClick={() => closeTermsModalState()}>×</a>
                                </Col>
                            </Row>
                            </div>
                            <hr /> 
                            {
                                <div dangerouslySetInnerHTML={{ __html: extHTML }} />
                            }
                        </Col>
                        </Row>
                    
                        <br />
                    </div>
                 </Container>
            </Modal>
            </>
    );
}
export default Disclaimer;