import React, { useState } from "react";
import styles from "./Signin.module.scss";
import Nav from "../../Components/Nav/Nav";
import SmallNav from "../../Components/navsmall/navsmall";
import { Link, useHistory  } from "react-router-dom";
import { validateAll } from 'indicative/validator';
import { Form, FormGroup, Label, Input, FormFeedback, FormText,Alert } from 'reactstrap';
import Constant from '../../constant.json';
import axios from 'axios';
import { useAuth } from "../../context/auth";
import { combineReducers } from 'redux';
import { sessionReducer } from 'redux-react-session';
import MetaTags from 'react-meta-tags';
import Swal from 'sweetalert2';
import {dflt, noop, ajax, l, isset, ref,blank} from '../../helpers';

const SignIn = () => {
  let history = useHistory();
  const [isLoading, setIsLoading] = useState(false);
  const [isLoggedIn, setLoggedIn] = useState(false);
  const [isError, setIsError] = useState(false);
  const [isLogin, setIsLogin] = useState(false);
  const { setAuthTokens,setAuthRole } = useAuth();
  
  const [state, setState] = useState({
    itemEmail: '',
    itemPassword: '',
    errors: {},
  })

  const reducers = {
    session: sessionReducer
  };
  const reducer = combineReducers(reducers);

  
  const {
    itemEmail, itemPassword, errors,
  } = state;
  const onChangeEmail = (value) => {

    setState(prevState => ({
      ...prevState,
      itemEmail: value
    }));
  };
  const onChangePassword = (value) => {

    setState(prevState => ({
      ...prevState,
      itemPassword: value
    }));
  };
  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      onSubmitSave(event);
    }
  }
  // Login button Click
  const onSubmitSave = (e) => {
    e.preventDefault();
    
    const messages = {
      'itemEmail.required': 'Email cannot be empty.',
      'itemEmail.email': 'Email cannot be valid.',
      'itemPassword.required': 'Password cannot be empty.',
    };
    const rules = {
      itemEmail: 'required|email',
      itemPassword: 'required|string',

    };
    validateAll(state, rules, messages)
      .then(() => {
        setIsLoading(true);
        postLogin()
          .then(function (response) {
            if (response.data.status == "1") {
              Swal.fire({
                icon: 'success',
                title: 'Logged in successfully',
                showConfirmButton: false,
                timer: 1500
              });
              setAuthTokens(response.data.accessToken);
              setLoggedIn(true);
              setIsLogin(true);    
              setIsLoading(false);
              setAuthRole(response.data.roles)
              localStorage.setItem('userID',response.data.id);
              localStorage.setItem('token',response.data.accessToken);
              localStorage.setItem('name',response.data.name);
              localStorage.setItem('email',response.data.email);
              localStorage.setItem('role',response.data.roles);
              if(!blank(response.data.image)){
                localStorage.setItem('profile_image_url',Constant.API_URL + Constant.PROFILE_RESIZE_IMAGE+response.data.image);
              }
              
              
              
              
              if(response.data.roles == 'ROLE_ADMIN'){
                history.push("/admin/dashboard");
              }else{
                history.push("/dashboard");
              }
              
              
            } else {
              const formattedErrors = {};
              formattedErrors['login_msg'] = response.data.message;
              Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: formattedErrors['login_msg'],
              });
              /*setState({
                ...state,
                errors: formattedErrors
              });*/
              setIsLoading(false);
            }
          })
          .catch(err => {
            const formattedErrors = {};
            formattedErrors['login_msg'] = "Email or Password not match."
            Swal.fire({
              icon: 'error',
              title: 'Oops...',
              text: formattedErrors['login_msg'],
            });
            /*setState({
              ...state,
              errors: formattedErrors
            });*/
            setIsLoading(false);
          }) 

      })
      .catch(errors => {
        const formattedErrors = {
        };
        errors.forEach(error => formattedErrors[error.field] = error.message);
        setState({
          ...state,
          errors: formattedErrors
        });
      })

  }
  // End Login 

  // Login check API
  async function postLogin() {
    const config = {
      method: 'post',
      url: Constant.API_URL + '/api/auth/signin',
      data: {
        email: itemEmail,
        password: itemPassword,
      }
    }
    let rs = await axios(config);
    return rs
  }

  const [focused, setFocus] = useState("");
  return (
    
    <React.Fragment>
      <MetaTags>
        <title>Login</title>
        <meta name="description" content="Blaze Broker" />
        <meta property="og:title" content="Blaze Broker - Login" />
      </MetaTags>
      <span className="d-none d-md-block">
        <Nav />
      </span>
      <span className="d-block d-md-none">
        <SmallNav />
      </span>
      <div className={isLoading ? "loader" : "loaderhide"}></div>
      <section className={styles.register_container}>
        <div className={styles.shadow_box}>
          <div className={styles.content_container}>
          {
              isLogin ? (
                <Alert color="success">
                  Log in Success.!
                </Alert>
              ) :
              ''
            }
            {
              errors.login_msg ? (
                <Alert color="danger">
                  {errors.login_msg}
                </Alert>
              ) : ''
            }
            <h1 className={styles.top_heading}>SIGN IN</h1>
            {/* EMAIL FIELD */}
            <div
              className={`${
                focused === "email"
                  ? styles.custom_input_focus
                  : styles.custom_input
              }`}
            >
              <span>
                <i class="fa fa-envelope" aria-hidden="true"></i>
              </span>
              <Input
                invalid = {errors.itemEmail}
                onFocus={() => setFocus("email")}
                onBlur={() => setFocus("")}
                type="text"
                placeholder="Enter Your Email"
                onChange={(e) => onChangeEmail(e.target.value)}
                onKeyDown={handleKeyDown}
              />
            </div>
            <FormFeedback style={{display:'block',textAlign:'center'}}>{errors.itemEmail}</FormFeedback>
            {/* PASSWORD FIELD */}
            <div
              className={`${
                focused === "password"
                  ? styles.custom_input_focus
                  : styles.custom_input
              }`}
            >
              <span>
                <i class="fa fa-lock" aria-hidden="true"></i>
              </span>
              <Input
                invalid = {errors.itemPassword}
                onFocus={() => setFocus("password")}
                onBlur={() => setFocus("")}
                type="password"
                placeholder="Enter Your Password"
                onChange={(e) => onChangePassword(e.target.value)}
                onKeyDown={handleKeyDown}
              />
            </div>
            <FormFeedback style={{display:'block',textAlign:'center'}}>{errors.itemPassword}</FormFeedback>
            <div className={styles.btn_container} >
              {/* <Link to="/dashboard"> */}
                <button className="btn" onClick={(e) => onSubmitSave(e)}>
                  Log In
                </button>
              {/* </Link> */}
            </div>
            <small className={styles.text_bottom}>
              <Link to="/send-email">Forgot Password ?</Link>
            </small>
          </div>
          
          <div className={styles.register_footer}>
            <small className={styles.text_bottom} style={{ fontWeight : 'bold', fontSize:'16px' }}>
              Don't have an account? <Link to="/register">Sign up Now</Link>
            </small>
          </div>
        </div>
      </section>
    </React.Fragment>
  );
};
export default SignIn;
