import * as Fingerprint2 from 'fingerprintjs2'
import FingerprintJS from '@fingerprintjs/fingerprintjs'
const fpPromise = FingerprintJS.load()
/* Shorter Log Function */
export function l(){
    for(var i=0;i<arguments.length;i++){
        console.log(arguments[i]);
    }
}
/* isset like PHP */
export function isset(){
    var sset = true;
    for(var i=0;i<arguments.length;i++){
        sset = sset && (typeof arguments[i] !== undefined && arguments[i]!=null);
    }
    return sset;
}
/* check file exist or not */
export function checkFileExist(urlToFile) {
  var xhr = new XMLHttpRequest();
  xhr.open('HEAD', urlToFile, false);
  xhr.send();
   
  if (xhr.status == "404") {
      return false;
  } else {
      return true;
  }
}
export function ImageExist(url) {
  var img = new Image();
  img.src = url;
  return img.height != 0 ? true : false;
}
/* dflt(a,b,c) if a isset, then a else b */
export function dflt(){
	for(var i=0;i<arguments.length;i++){
        if(isset(arguments[i])){
        	return arguments[i];
        }
    }
    return null;
}
/* noop (blank function) */
export function noop(){
    return function(){ };
}
/* forEach supports for objects + arrays */
export function each(obj, func){
    if(Array.isArray(obj)){ return obj.forEach(func); }
    Object.keys(obj).forEach(function(key){
        func(obj[key], key);
    });
}
/* Object map function to object or array */
export function map(obj, func){
    if(!isset(obj)){ return []; }
    if(Array.isArray(obj)){ return obj.map(func); }
    var arr = Object.keys(obj).reduce(function(prev, obj_ndx, ndx) {
        prev[ndx] = func(obj[obj_ndx], obj_ndx, ndx);
        return prev;
    }, []);
    return arr;
}
/* empty or null string check */
/*
blank('', 'asdf', '' ) = true
blank('') = true
blank('asdf') = false */
export function blank(){
    var blank = true;
    for(var i=0;i<arguments.length;i++){
        var v = arguments[i];
        if(!isset(v) || v.trim() == ""){
            return true;
        }
    }
    return false;
}
/* Deep Extend object extend({}, objectA, ObjB, ...) */
export function extend(out) {
  out = out || {};

  for (var i = 1; i < arguments.length; i++) {
    var obj = arguments[i];

    if (!obj)
      continue;

    for (var key in obj) {
      if (obj.hasOwnProperty(key)) {
        if (typeof obj[key] === 'object')
          out[key] = extend(out[key], obj[key]);
        else
          out[key] = obj[key];
      }
    }
  }

  return out;
}

/* pluck out values you'd like */
export function pluck(){
    var cleaner = {};
    var data = extend({}, {}, arguments[0]);
    for(var i=1;i<arguments.length;i++){
        var ndx = arguments[i];
        if(isset(data[ndx])){ // only set, if already set
            cleaner[ndx] = data[ndx];
        }
    }
    return cleaner;
}

/* ie9+ ajax */
export function ajax(opts){
  var options = extend({}, {
    type        : 'POST',
    url         : '/not-set-url-ajax',
    data        : null,
    parseJSON   : true,
    contentType : 'application/x-www-form-urlencoded',
    success     : noop,
    error       : noop,
    done        : noop
  }, opts);

  var param = function(obj) {
    var stringifyParam = function(data, topLevel, keyProp) {
      var string = '';
      for (var key in data) {
          if(keyProp && topLevel[keyProp] ) {
              if ( (topLevel[keyProp] instanceof Array&&topLevel[keyProp].indexOf(data[key])!==0) ) {
                  string += keyProp;
              } else if ( (topLevel[keyProp] instanceof Object&&topLevel[keyProp][key]) ) {
                  string += keyProp;
              }
          }
          if (typeof(topLevel[key])=='undefined') {
              string += '[' + key + ']';
          }
          if (data[key] instanceof Array) {
              string += stringifyParam(data[key], topLevel, key);
          } else if(data[key] instanceof Object){
              string += stringifyParam(data[key], topLevel, key);
          } else {
              if (typeof(topLevel[key])!='undefined') {
                  string += key;
              }
              string += '=' + data[key];
              string += '&';
          }
      }
        return string;
    },
    toParam = function(data){
        var string = stringifyParam(data,data);
        return encodeURI(string.substring(0,string.length-1).split(' ').join('+'));
    };
    return toParam(obj);
  };

  var request = new XMLHttpRequest();
      request.open(options.type, options.url, true);
      request.setRequestHeader('Content-Type', options.contentType + '; charset=UTF-8');

      // on request load
      request.onload = function() {
        if (request.status >= 200 && request.status < 400) {
          // Success!
          var data = request.responseText;
          if(options.parseJSON){
            data = JSON.parse(request.responseText);
          }
          dflt(options.success, noop)(data);
          dflt(options.done, noop)(data);
        } else {
          // We reached our target server, but it returned an error
          var data = {ok:false,msg:"Oops, unexpected error"};
          dflt(options.error, noop)(data);
          dflt(options.done, noop)(data);
        }
      };
      // on request error
      request.onerror = function() {
        // There was a connection error of some sort
        var data = {ok:false, msg:"Oops, this request had a connection error"};
        dflt(options.error, noop)(data);
        dflt(options.done, noop)(data);
      };

      var raw_data = param(options.data);
      request.send(raw_data);
}

/* React Specific Helpers - potential: move to seperate file in components Data.jsx */
export function onChange(name, value, substate){
  // name, value (assumes bind 'this')
  if(isset(substate)){
    var temp = this.state[substate];
        temp[name] = value;
    var obj = {};
        obj[substate] = temp;
    this.setState(obj);
  }else{
    // assume no substate & use this.state
    var temp = this.state;
        temp[name] = value;
    this.setState(temp);
  }
}
/* Dynamically create a reference w/ a given name for a react component */
export function ref(name, context){
  return function(node){
    context.references = dflt(context.references, {});
    context.references[name] = node;
    return name;
  }
}

/* validate form from front end */
export function validate(rules, onValid, context, onInvalid){
  /* rules =
    {email:'isEmail',
    name:['notBlank', function(val){ return {ok:false, msg:'Needs to start with B'} }]}
  */
  var default_rules = {
		notBlank : {
			msg:'Oops, this field is required',
			action: function(val){
				return isset(val) && !blank(val);
			}
		},
		isEmail  : {
			msg:"Oops, this isn't a valid email address",
			action:function(val){
          val = dflt(val, '').trim();
				  var re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
	  			return re.test(val);
			}
		}
	}, data = {};
  // start checking against rules
	var isValid = true, error_msg = '', refs = context.references, trouble_ndx = null;
  each(rules, function(rulez, comp_name){
    if(!isValid){ return false; }
    // get value of component - possibly just make a getValue function for each component
    var value = refs[comp_name].state.value;
    data[comp_name] = value;
    // lookup one of the default rules & test it
    if(!Array.isArray(rulez)){
      rulez = [rulez]; // create a 1 element array to iterate
    }
    // iterate all rules for this fella
    each(rulez, function(rule, i){
      if(!isValid){ return false; }

      var lookup = function(){ l('ERROR: Calling default validation lookup function'); return false; }
      if(typeof rule == 'string'){
        lookup = default_rules[rule.trim()];
				if(!isset(lookup)){
					throw new Error("Oops, '" + rule + "' is not a valid rule");
				}
      }else if(typeof rule == 'function'){
        // call function w/ value for result
        lookup = rule;
      }

      if(!lookup.action(value) && isValid){
        isValid = false;
        error_msg = lookup.msg;
        trouble_ndx = comp_name;
        return false;
      }
    });
  });
  // valid or not
  if(isValid && !isset(trouble_ndx)){
    dflt(onValid, noop).bind(context)(data);
  }else{
    // update ui for that component to be error & callback
    refs[trouble_ndx].error(error_msg);
    dflt(onInvalid, noop).bind(context)(trouble_ndx, error_msg);
  }
}

export function makeuniqueID() {
  const uniq = localStorage.getItem("uniqueID");
  if(uniq != '' && uniq != null){
    return uniq;
  }else{
    var id = Math.floor(Math.random() * Date.now())
    localStorage.setItem("uniqueID",id);
    return id;
  }
  
  }
window.config = dflt(window.config, {});
