import React, { useState } from "react";
import "./topNav.scss";
import { Button, Badge } from "reactstrap";
import SearchIcon from "../../Assets/search-icon.svg";
import support from "../../Assets/support-icon.svg";
import arrowDown from "../../Assets/small-down.svg";
import Dp from "../../Assets/dp.jpg";
import { Link } from "react-router-dom";
import { useAuth } from "../../context/auth";
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import {dflt, noop, ajax, l, isset,blank, ref,makeuniqueID} from '../../helpers';
const TopNav = () => {
  const auth = useAuth();
  let image_path = localStorage.getItem('profile_image_url');
  const [showNotification, setShowNotification] = useState(false);
  const [showAccount, setShowAccount] = useState(false);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [dropdownOpen2, setDropdownOpen2] = useState(false);
  const { setAuthTokens,setAuthRole } = useAuth();
  const toggle = () => setDropdownOpen((prevState) => !prevState);
  const toggle2 = () => setDropdownOpen2((prevState) => !prevState);
  const logout = () => {
    setAuthTokens(null);
    setAuthRole(null);
    localStorage.setItem('userID','');
    localStorage.setItem('token','');
    localStorage.setItem('name','');
    localStorage.setItem('email','');
    localStorage.setItem('role','');
    localStorage.setItem('profile_image_url','');
  }
  const _tokenexpering = localStorage.getItem('_expertime');
const current_time = new Date();
if(isset(_tokenexpering)){
   
  if(Date.parse(current_time) > Date.parse(_tokenexpering)){
    
    setAuthTokens(null);
    setAuthRole(null);
    localStorage.setItem('userID','');
    localStorage.setItem('token','');
    localStorage.setItem('name','');
    localStorage.setItem('email','');
    localStorage.setItem('role','');
    localStorage.setItem('profile_image_url','');
    const sevenDaysFromNow = new Date(new Date().setHours(new Date().getHours() + 1));
    
    localStorage.setItem('_expertime',sevenDaysFromNow);
  }
}
  return (
    <div>
      <div className="nav_container">
        <div className="other_details_container">
          <Dropdown className="ml-auto" isOpen={dropdownOpen2} toggle={toggle2}>
            <DropdownToggle caret className="button_icon">
              <span className="martButton">{localStorage.getItem('name')}</span>

              <img src={arrowDown} className="img img-fluid" />
            </DropdownToggle>
            <DropdownMenu>
              {
                auth.authRole == 'ROLE_USER' ? 
                  <Link to="/setting">
                  <DropdownItem>My Account</DropdownItem>
                </Link>
                : ''
              }
              {
                auth.authRole == 'ROLE_ADMIN' ? 
                  <Link to="/admin/setting">
                  <DropdownItem>My Account</DropdownItem>
                </Link>
                : ''
              }
              
              <DropdownItem divider />
              <Link onClick={(e) => logout(e)}>
                <DropdownItem>Logout</DropdownItem>
              </Link>
            </DropdownMenu>
          </Dropdown>

          <div className="dp">
            <img src={image_path != '' ? image_path : Dp} className="img img-fluid" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default TopNav;
