import React, { useState, useEffect } from "react";
import { Route } from "react-router-dom";
import {
  Container,
  Col,
  Row,
  FormGroup,
  Label,
  Input,
  CustomInput,
  FormFeedback,
  Button,
} from "reactstrap";
import styles from "./business.module.scss";
import "./override.scss";
import FormInput from "../../Components/FormInput/FormInput";
import SideNav from "../../Components/Admin/SideNav/SideNav";
import { TabContent, TabPane, Nav, NavItem, NavLink } from "reactstrap";
import classnames from "classnames";
import edit from "../../Assets/edit.svg";
import TopNav from "../../Components/TopNav/TopNav";
import NavVenderSm from "../../Components/Admin/NavVenderSm/NavVenderSm";
import { useAuth } from "../../context/auth";
import axios from "axios";
import Constant from '../../constant.json';
import { validateAll } from 'indicative/validator';
import Swal from 'sweetalert2';
const Setting = (props) => {
  const auth = useAuth();
  const [isLoading, setIsLoading] = useState(false);
  const [idstatus, setIdstatus] = useState(0);
  const [activeTab, setActiveTab] = useState("1");
  const [focused, setFocus] = useState("");
  const [StateList, setStateList] = useState([]);
  const [state, setState] = useState({
    UserData: {},
    name: '',
    shop_name: '',
    email: '',
    contact: '',
    // phone : '',
    address: '',
    province: '',
    city: '',
    area: '',
    password_confirmation: '',
    password: '',
    errors: {},

  })
  const {
    UserData, errors, area, city, province, address, contact, email, shop_name, name, password, password_confirmation
  } = state;
  useEffect(() => {
    (async () => {
      await GetStateList();
      await GetUSerDetails();
    })();
  }, []);
  const GetStateList = async () => {
    setIsLoading(true);
    let data = [];
    const config = {
      method: 'get',
      url: Constant.API_URL + '/api/state/1',
      headers: {
        'Content-Type': 'application/json',
        'x-access-token': auth.authTokens
      },
    }
    await axios(config)
      .then(async function (response) {
        let data_result = response.data;
        setStateList(data_result.data)

      })
    setIsLoading(false);
  }
  async function GetUSerDetails() {
    setIsLoading(true);
    let data = [];
    const config = {
      method: 'get',
      url: Constant.API_URL + '/api/auth/user',
      headers: {
        'Content-Type': 'application/json',
        'x-access-token': auth.authTokens
      },
    }
    await axios(config)
      .then(function (response) {
        let data_result = response.data;
        let result = data_result.data;
        setState({
          ...state,
          UserData: result,
          name: result.name,
          shop_name: result.shop_name,
          email: result.email,
          contact: result.contact,
          address: result.address,
          province: result.province,
          city: result.city,
          area: result.area,
        });

      })
    setIsLoading(false);
  }
  let reloaddata = () => {
    GetUSerDetails();
  }
  const onupdateuserdata = (e) => {
    e.preventDefault();

    const messages = {
      'name.required': 'Name cannot be empty.',
      // 'name.max': 'Name cannot be more then 15 char.',
      'shop_name.required': 'Shop name cannot be empty.',
      'shop_name.max': 'Shop name cannot be more then 20 char.',
      'contact.required': 'Contact no cannot be empty.',
      'contact.min': 'please enter valid number',
      'contact.max': 'please enter valid number',
      'address.required': 'Address cannot be empty.',
      'address.max': 'Address cannot be more then 50 char.',
      'province.required': 'State cannot be empty.',
      'city.required': 'City cannot be empty.',
      'city.max': 'City cannot be more then 15 char.',

    };
    const rules = {
      name: 'required',
      shop_name: 'required|max:20',
      contact: 'required|max:10|min:10',
      address: 'required|max:50',
      city: 'required|max:15',
      province: 'required',

    };
    validateAll(state, rules, messages).then(() => {
      postUserDetails()
        .then(function (response) {
          if (response.data.status == "1") {
            setState({
              ...state,
              errors: {}
            });
            localStorage.setItem('name', name);
            let result = response.data;
            Swal.fire({
              icon: 'success',
              text: result.message,
            });
          }
        }).catch(err => {

          let formattedErrors = {};
          formattedErrors['error_info'] = "There is some error please contact admin."
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: formattedErrors['error_info'],
          });

        })
    }).catch(errors => {
      const formattedErrors = {

      };

      errors.forEach(error => formattedErrors[error.field] = error.message);

      setState({
        ...state,
        errors: formattedErrors
      });
    })

  }
  async function postUserDetails() {


    const config = {
      method: 'post',
      url: Constant.API_URL + '/api/auth/basicupdate',
      data: {
        name: name,
        shop_name: shop_name,
        contact: contact,
        address: address,
        city: city,
        province: province
      },
      headers: {
        'Content-Type': 'application/json',
        'x-access-token': auth.authTokens
      },
    }
    const result = await axios(config);
    return result;

  }
  const resetpassword = (e) => {
    e.preventDefault();

    const messages = {
      'password.required': 'Password cannot be empty.',
      'password.confirmed': 'Password and Confirm Password cannot match.',
      'password_confirmation.required': 'Confirm Password cannot be empty.',

    };
    const rules = {
      password: 'required | confirmed',
      password_confirmation: 'required',

    };
    validateAll(state, rules, messages).then(() => {
      postChangePassword()
        .then(function (response) {
          if (response.data.status == "1") {
            setState({
              ...state,
              password_confirmation: '',
              password: '',
              errors: {}
            });
            let result = response.data;
            Swal.fire({
              icon: 'success',
              text: result.message,
            });
          }
        }).catch(err => {

          let formattedErrors = {};
          formattedErrors['error_info'] = "There is some error please contact admin."
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: formattedErrors['error_info'],
          });

        })
    }).catch(errors => {
      const formattedErrors = {

      };

      errors.forEach(error => formattedErrors[error.field] = error.message);

      setState({
        ...state,
        errors: formattedErrors
      });
    })
  }
  async function postChangePassword() {


    const config = {
      method: 'post',
      url: Constant.API_URL + '/api/auth/resetpassword',
      data: {
        password: password,

      },
      headers: {
        'Content-Type': 'application/json',
        'x-access-token': auth.authTokens
      },
    }
    const result = await axios(config);
    return result;

  }
  const onChangeEvent = (e) => {

    const value = e.target.value;
    setState({
      ...state,
      [e.target.name]: value
    });
  };
  const toggle = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };
  return (
    <div className={` ${styles.businessMain} `}>
      <span className="d-none d-md-block">
        <TopNav />
      </span>
      <span className="d-block d-md-none">
        <NavVenderSm />
      </span>

      <div className={isLoading ? "loader" : "loaderhide"}></div>
      <div className={styles.main} style={{ background: 'white' }} >
        <Row>
          <Col xs="2" md="2" className="d-none d-md-block">
            <SideNav />
          </Col>

          <Col xs="12" sm="12" md="12" className={`${styles.formMain}  `} >
            <Container
              className={`${styles.container} mt-4 mb-4 mr-0 ml-0 p-0`}

            >
              <div className={styles.mainHeading}>

              </div>
              <div className={styles.promoNav}>
                <Nav tabs>
                  <div>
                    <NavItem>
                      <NavLink
                        className={classnames({ active: activeTab === "1" })}
                        onClick={() => {
                          toggle("1");
                        }}
                      >
                        Basic Information
                      </NavLink>
                    </NavItem>
                  </div>
                  <div>
                    <NavItem>
                      <NavLink
                        className={classnames({ active: activeTab === "2" })}
                        onClick={() => {
                          toggle("2");
                        }}
                      >
                        Reset Password
                      </NavLink>
                    </NavItem>
                  </div>
                  {/* <div>
                    <NavItem>
                      <NavLink
                        className={classnames({ active: activeTab === "3" })}
                        onClick={() => {
                          toggle("3");
                        }}
                      >
                        Admin Settings
                      </NavLink>
                    </NavItem>
                  </div> */}

                </Nav>
              </div>
              <TabContent activeTab={activeTab}>
                <TabPane tabId="1">
                  <div className={styles.form}>

                    <Row>
                      <Col xs="12" md="9" sm="9">

                        <FormGroup className={`${styles.formInput}`} row>
                          <Label for="exampleSelect" sm={4}>
                            Owner Name<span className={styles.star}>*</span>
                          </Label>
                          <Col sm={8}>
                            <Input
                              invalid={errors.name}
                              className={styles.input}
                              type="text"
                              name="name"
                              id="name"
                              value={name}
                              defaultValue={UserData.name}
                              onChange={(e) => onChangeEvent(e)}
                            >

                            </Input>
                            <FormFeedback style={{ display: 'block' }}>{errors.name}</FormFeedback>
                          </Col>
                        </FormGroup>
                        {/* <FormInput
                          label="Owner Name"
                          type="text"
                          place="Full Name"
                          value={UserData.name}
                        /> */}
                      </Col>
                      <Col xs="3"></Col>
                    </Row>

                    <Row>
                      <Col xs="12" md="9" sm="9">

                        <FormGroup className={`${styles.formInput}`} row>
                          <Label for="exampleSelect" sm={4}>
                            Shop Name<span className={styles.star}>*</span>
                          </Label>
                          <Col sm={8}>
                            <Input
                              invalid={errors.shop_name}
                              className={styles.input}
                              type="text"
                              name="shop_name"
                              id="shop_name"
                              value={shop_name}
                              defaultValue={UserData.shop_name}
                              onChange={(e) => onChangeEvent(e)}
                            >

                            </Input>
                            <FormFeedback style={{ display: 'block' }}>{errors.shop_name}</FormFeedback>
                          </Col>
                        </FormGroup>
                        {/* <FormInput
                          label="Shop Name"
                          type="text"
                          place="XYZ Store"
                        />*/}
                      </Col>
                      <Col xs="3"></Col>
                    </Row>

                    <Row>
                      <Col xs="12" md="9" sm="9">
                        <FormGroup className={`${styles.formInput}`} row>
                          <Label for="exampleSelect" sm={4}>
                            Email<span className={styles.star}>*</span>
                          </Label>
                          <Col sm={8}>
                            <Input
                              invalid={errors.email}
                              className={styles.input}
                              type="email"
                              name="email"
                              id="email"
                              disabled={true}
                              Value={UserData.email}
                            >

                            </Input>
                            <FormFeedback style={{ display: 'block' }}>{errors.email}</FormFeedback>
                          </Col>
                        </FormGroup>
                        {/* <FormInput label="Email" type="email" place="@" /> */}
                      </Col>
                      <Col xs="3"></Col>
                    </Row>

                    <Row>
                      <Col xs="12" md="9" sm="9">
                        <FormGroup className={`${styles.formInput}`} row>
                          <Label for="exampleSelect" sm={4}>
                            Phone Number<span className={styles.star}>*</span>
                          </Label>
                          <Col sm={8}>
                            <Input
                              invalid={errors.contact}
                              className={styles.input}
                              type="number"
                              placeholder="+1"
                              name="contact"
                              id="contact"
                              value={contact}
                              defaultValue={UserData.contact}
                              onChange={(e) => onChangeEvent(e)}
                            ></Input>
                            <FormFeedback style={{ display: 'block' }}>{errors.contact}</FormFeedback>
                          </Col>
                        </FormGroup>
                      </Col>
                      <Col xs="3"></Col>
                    </Row>

                    {/* <Row>
                      <Col xs="12" md="9" sm="9">
                        <FormGroup className={`${styles.formInput}`} row>
                          <Label for="exampleSelect" sm={4}>
                            Phone Number<span className={styles.star}>*</span>
                          </Label>
                          <Col sm={8}>
                            <Input
                            invalid = {errors.phone}
                              className={styles.input}
                              type="number"
                              placeholder="+1-Area Code-Land Line No#"
                              name="phone"
                              id="phone"
                              defaultValue = {UserData.phone}
                              onChange={(e) => onChangeEvent(e)}
                            ></Input>
                            <FormFeedback style={{display:'block'}}>{errors.phone}</FormFeedback>
                          </Col>
                        </FormGroup>
                      </Col>
                    </Row> */}

                    <Row>
                      <Col xs="12" md="9" sm="9">
                        <FormGroup className={`${styles.formInput}`} row>
                          <Label for="exampleSelect" sm={4}>
                            Shop Address<span className={styles.star}>*</span>
                          </Label>
                          <Col sm={8}>
                            <Input
                              invalid={errors.address}
                              className={styles.input}
                              type="text"
                              name="address"
                              id="address"
                              value={address}
                              defaultValue={UserData.address}
                              onChange={(e) => onChangeEvent(e)}
                            >

                            </Input>
                            <FormFeedback style={{ display: 'block' }}>{errors.address}</FormFeedback>
                          </Col>
                        </FormGroup>
                        {/* <FormInput
                          label="Shop Address"
                          type="text"
                          place="Shop No# 123, 1st Floor, ABC Road"
                        /> */}
                      </Col>
                      <Col xs="3"></Col>
                    </Row>

                    <Row>
                      <Col xs="12" md="9" sm="9">
                        <FormGroup className={`${styles.formInput}`} row>
                          <Label for="exampleSelect" sm={4}>
                            State<span className={styles.star}>*</span>
                          </Label>
                          <Col sm={8}>
                            <Input
                              invalid={errors.province}
                              className={styles.input}
                              type="select"
                              name="province"
                              id="province"
                              value={province}
                              onChange={(e) => onChangeEvent(e)}
                              defaultValue={UserData.province}
                            >
                              <option value="">Please choose your State</option>
                              {

                                StateList.map((item, index) => (
                                  <option key={index} value={item.id}>{item.name}</option>
                                ))

                              }
                            </Input>
                            <FormFeedback style={{ display: 'block' }}>{errors.province}</FormFeedback>
                          </Col>
                        </FormGroup>
                      </Col>
                      <Col xs="3"></Col>
                    </Row>

                    <Row>
                      <Col xs="12" md="9" sm="9">
                        <FormGroup className={`${styles.formInput}`} row>
                          <Label for="exampleSelect" sm={4}>
                            City<span className={styles.star}>*</span>
                          </Label>
                          <Col sm={8}>
                            <Input
                              invalid={errors.city}
                              className={styles.input}
                              type="text"
                              name="city"
                              id="city"
                              value={city}
                              onChange={(e) => onChangeEvent(e)}
                              defaultValue={UserData.city}
                            />

                            <FormFeedback style={{ display: 'block' }}>{errors.city}</FormFeedback>
                          </Col>
                        </FormGroup>
                      </Col>
                      <Col xs="3"></Col>
                    </Row>

                    <Row>
                      <Col xs="12" md="9" sm="9">
                        <FormGroup className={`${styles.formInput} `} row>
                          <Label for="exampleSelect" sm={4}>
                            Area<span className={styles.star}>*</span>
                          </Label>
                          <Col sm={8}>
                            <Input
                              invalid={errors.area}
                              className={styles.input}
                              type="select"
                              name="area"
                              id="area"
                              onChange={(e) => onChangeEvent(e)}
                              defaultValue={UserData.area}
                            >
                              <option>Please choose your Area</option>
                            </Input>
                            <FormFeedback style={{ display: 'block' }}>{errors.area}</FormFeedback>
                          </Col>
                        </FormGroup>
                      </Col>
                      <Col xs="3"></Col>
                    </Row>

                    <Row>
                      <Col xs="12">
                        <Button className={styles.btn} onClick={(e) => onupdateuserdata(e)}>Save</Button>
                      </Col>

                    </Row>
                  </div>

                </TabPane>
                <TabPane tabId="2">

                  <div className={styles.form}>

                    <Row>
                      <Col xs="12" md="9" sm="9">

                        <FormGroup className={`${styles.formInput}`} row>
                          <Label for="exampleSelect" sm={4}>
                            New Password<span className={styles.star}>*</span>
                          </Label>
                          <Col sm={8}>
                            <Input
                              invalid={errors.password}
                              className={styles.input}
                              type="password"
                              name="password"
                              id="password"
                              onChange={(e) => onChangeEvent(e)}
                              placeholder="Enter New Password"
                            />
                            <FormFeedback style={{ display: 'block' }}>{errors.password}</FormFeedback>
                          </Col>
                        </FormGroup>

                      </Col>

                    </Row>
                    <Row>
                      <Col xs="12" md="9" sm="9">

                        <FormGroup className={`${styles.formInput}`} row>
                          <Label for="exampleSelect" sm={4}>
                            Confirm Password<span className={styles.star}>*</span>
                          </Label>
                          <Col sm={8}>
                            <Input
                              invalid={errors.password_confirmation}
                              className={styles.input}
                              type="password"
                              name="password_confirmation"
                              id="password_confirmation"
                              onChange={(e) => onChangeEvent(e)}
                              placeholder="Enter Confirm Password"
                            />
                            <FormFeedback style={{ display: 'block' }}>{errors.password_confirmation}</FormFeedback>
                          </Col>
                        </FormGroup>

                      </Col>

                    </Row>
                    <Row>
                      <Col xs="12" md="9" sm="9">
                        <div className={styles.btn_container}>
                          <button onClick={(e) => resetpassword(e)}>Reset Password</button>
                        </div>
                      </Col>
                    </Row>
                  </div>

                </TabPane>
                {/* <TabPane tabId="3">

                  <div className={styles.form}>

                    <Row>
                      <Col xs="12" md="12" sm="12">
                        <FormGroup className={`${styles.formInput}`} row>
                          <Label  md="3" sm="6" xs="8" style={{paddingTop:'0 !important'}}>
                            ID Proof required on checkout
                          </Label>
                          <Col xs="2" md="2" sm="2" style={{paddingTop : '8px'}}>
                          <CustomInput type="switch" id={`productstatusswitch_${1}`} name="productstatusswitch"
                            checked={idstatus}
                             onChange={(e)=> setIdstatus(!idstatus)}
                            />
                          </Col>
                          
                        </FormGroup>
                      </Col>


                    </Row>
                  </div>

                </TabPane> */}

              </TabContent>
            </Container>
          </Col>
        </Row>
      </div>
    </div>
  );
};
export default Setting;
